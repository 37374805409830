import Loadable from "@loadable/component";

const QuicklookModal = Loadable(
  () =>
    import(
      /* webpackChunkName: "product_quicklook_modal_component" */ "./quicklookModal"
    )
);

export default QuicklookModal;
