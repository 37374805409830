import React, { useState } from "react";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import { showPageLoader } from "@/redux/actions/common.action";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import AuthAnaltyicsEvent from "@/services/analytics/main/authentication";
import { logOut } from "@/redux/actions/authConfig.action";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  useCountryShort,
  useIsArabicEnabled,
  useIsRTL,
  useLanguage
} from "@/hooks";
import { selectCountryCode, selectStaticAssets } from "@/util/selectors";
import { setPreferredLanguage } from "@/util";
import "./style.scss";

const AccountSettings = ({
  openLink,
  closeMenu,
  onToggleLanguageList,
  openCountryModal
}) => {
  const { t } = useTranslation("megaMenu");
  const [showLanguageList, setShowLanguageList] = useState(false);
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const countryCode = useAppSelector(selectCountryCode);
  const countryShort = useCountryShort();
  const userLoggedIn = useAppSelector(state => state.authReducer.userLoggedIn);
  const isRTL = useIsRTL();
  const isArabicEnabled = useIsArabicEnabled();
  const lisaUrl = useAppSelector(
    state => state.common.configSettings?.lisaUrl?.url
  );
  const staticAssets = useAppSelector(selectStaticAssets);

  const onOpenLink = redirectLink => {
    if (redirectLink !== staticAssets?.watsappUrlSlug) {
      openLink(redirectLink);
    } else {
      window.open(redirectLink, "_blank");
    }
  };

  const onOpenCountryModal = () => {
    closeMenu();
    openCountryModal();
  };

  const onLogOutClick = isTrackPrevHistory => {
    closeMenu();
    AuthAnaltyicsEvent.logout();
    dispatch(logOut(isTrackPrevHistory));
  };

  const toggleLanguageList = () => {
    setShowLanguageList(!showLanguageList);
    onToggleLanguageList(!showLanguageList);
  };

  const commonSettingsData = {
    showWithoutLogin: true,
    value: "",
    onClick: onOpenLink
  };

  const settingsList: {
    id: number;
    heading: string;
    enHeading: string;
    redirectionUrl: string;
    showWithoutLogin: boolean;
    value: string;
    flagClass?: string;
    onClick: (...args: any) => void;
    hasFlag?: boolean;
    isLanguage?: boolean;
    className?: string;
  }[] = [
    {
      ...commonSettingsData,
      id: 1,
      heading: t("myAccount"),
      enHeading: t("myAccount", { lng: "en" }),
      redirectionUrl: userLoggedIn
        ? "/my-account/account-overview/"
        : "/login/?my-account/account-overview/"
    },
    {
      ...commonSettingsData,
      id: 2,
      heading: t("myOrders"),
      enHeading: t("myOrders", { lng: "en" }),
      redirectionUrl: userLoggedIn
        ? "/my-account/my-orders/"
        : "/login/?my-account/my-orders/"
    },
    {
      ...commonSettingsData,
      id: 3,
      heading: t("trackOrder"),
      enHeading: t("trackOrder", { lng: "en" }),
      redirectionUrl: userLoggedIn ? "/my-account/my-orders/" : "/track-order/"
    },
    {
      ...commonSettingsData,
      id: 4,
      heading: t("needHelp"),
      enHeading: t("needHelp", { lng: "en" }),
      redirectionUrl: "/need-help/"
    },
    {
      ...commonSettingsData,
      id: 5,
      heading: t("contactUs"),
      enHeading: t("contactUs", { lng: "en" }),
      redirectionUrl: "/send-us-email/"
    },
    {
      ...commonSettingsData,
      id: 6,
      heading: t("whatsApp"),
      enHeading: t("whatsApp", { lng: "en" }),
      redirectionUrl: staticAssets?.watsappUrlSlug
    },
    lisaUrl && {
      ...commonSettingsData,
      id: 7,
      heading: t("liveShopping"),
      enHeading: t("liveShopping", { lng: "en" }),
      redirectionUrl: lisaUrl,
      className: "live_streaming_menu_item"
    },
    {
      id: 8,
      heading: t("country"),
      enHeading: t("country", { lng: "en" }),
      redirectionUrl: "",
      showWithoutLogin: true,
      value: countryCode,
      flagClass: countryShort && countryShort.toLowerCase(),
      onClick: onOpenCountryModal,
      hasFlag: true
    },
    {
      id: 9,
      heading: t("language"),
      enHeading: t("language", { lng: "en" }),
      redirectionUrl: "",
      showWithoutLogin: true,
      value: isRTL ? "عربى" : "ENG",
      onClick: toggleLanguageList,
      isLanguage: true
    },
    {
      id: 10,
      heading: t("signOut"),
      enHeading: t("signOut", { lng: "en" }),
      redirectionUrl: "",
      showWithoutLogin: false,
      value: "",
      onClick: () => onLogOutClick(false)
    }
  ].filter(Boolean);

  const getLanguageSwitchedUrl = languageCode =>
    window.location.href.replace(/\/(en-|ar-)/g, `/${languageCode}-`);

  const handleLangChange = languageCode => {
    dispatch(showPageLoader());
    setPreferredLanguage(languageCode);
    CommonAnalyticsEvent.changeLanguage({
      code: languageCode
    });
  };

  const languageList = [
    { id: 1, language: "English", languageCode: "en" },
    ...(isArabicEnabled
      ? [{ id: 2, language: "عربى", languageCode: "ar" }]
      : [])
  ];

  if (showLanguageList)
    return (
      <ul className="sub_categories_wrapper languageList">
        <h3>
          <span className="back_btn" onClick={toggleLanguageList} />

          {t("language")}
        </h3>

        {languageList.map(item => (
          <a
            className={cn(
              "language_switch_mobile",
              language === item.languageCode
                ? "language_switch"
                : "language_not_selected"
            )}
            key={item.id}
            href={getLanguageSwitchedUrl(item.languageCode)}
            onClick={() => handleLangChange(item.languageCode)}
          >
            {item.language}
          </a>
        ))}
      </ul>
    );

  return (
    <ul className="account_setting_wrapper">
      {settingsList
        .filter(data => userLoggedIn || data.showWithoutLogin)
        .map(item => {
          const handleSettingItemClick = () => {
            item.onClick(item.redirectionUrl);
            CommonAnalyticsEvent.navClick({
              clickText: `${item.enHeading} ${
                ((item.hasFlag || item.isLanguage) && item.value) || ""
              }`,
              link: item.redirectionUrl
            });
          };

          return (
            <li
              key={item.id}
              className={cn({
                country_flag: item.hasFlag,
                [item.className]: !!item.className
              })}
              onClick={handleSettingItemClick}
            >
              <p>
                {item.heading}{" "}
                {item.hasFlag && (
                  <span>
                    <CircleFlag
                      countryCode={item.flagClass?.toLowerCase()}
                      width={18}
                      className="country_image"
                    />
                    {item.value}
                  </span>
                )}{" "}
                {item.isLanguage && <span>{item.value}</span>}
              </p>
            </li>
          );
        })}
    </ul>
  );
};

export default AccountSettings;
