export const GA_EVENTS = {
  checkout: "checkout",
  impressions: "impressions",
  productListingPage: "product_listing_page",
  subscribeNewsletter: "newsletter_subscription",
  loyaltyProgramRedeemButtonClick: "loyalty_program_redeem_button_click",
  loyaltyProgramViewed: "loyalty_program_viewed",
  loyaltyProgramRedeemConfirmClick: "loyalty_program_redeem_confirm_click",
  productSearch: "product_search",
  viewCategory: "view_category",
  viewSubcategory: "view_subcategory",
  countryChange: "country_change",
  pageType: "page_type",
  homePage: "home_page",
  leadSubmitted: "lead_submitted",
  pageBrowse: "pageBrowse",
  guestAddress: "guest_address",
  orderCancellation: "order_cancellation",
  profileUpdate: "profile_update",
  cartPage: "cart_page"
};
