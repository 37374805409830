import React, { useEffect, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";

import { getBrand } from "@/util";
import { useLanguage } from "@/hooks";

import { fetchHomepageSection } from "@/redux/actions/page.action";
import { seoSlice } from "@/redux/features";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import { getMetaTags, getSEOStructuredLogo } from "./helpers";

const Page: FC<{
  title?: string;
  description?: string;
  indexable?: boolean;
  id?: string;
  className?: string;
  children: React.ReactNode;
}> = ({
  title: titleOverridden,
  indexable: indexableOverridden,
  description: descriptionOverridden,
  children,
  id,
  className
}) => {
  const language = useLanguage();
  const location = useLocation();
  const { t } = useTranslation("seo");

  const meta = useAppSelector(state => state.seo.meta);
  const countryCode = useAppSelector(
    state => state.common?.settings?.countryCode
  );

  const defaultTitle = `${t("bfl")} - ${t("onlineShopping")} ${countryCode}`;
  const title = titleOverridden || meta?.title || defaultTitle;
  const description =
    descriptionOverridden || meta?.description || t("defaultPageDescription");
  const isIndexable = indexableOverridden || meta?.isIndexable || false;

  const cdnImageSettings = useAppSelector(
    state => state.common.configSettings?.cdnImages
  );
  const image = meta?.image || cdnImageSettings?.metaBannerImage;

  const storeId = useAppSelector(state => state.page.homepageState.storeId);
  const countryId = useAppSelector(state => state.page.homepageState.countryId);
  const shopByStore = useAppSelector(state => state.page.storeList);
  const logoStructuredData = useMemo(
    () => getSEOStructuredLogo(getBrand(shopByStore, storeId)),
    [shopByStore, storeId]
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isIndexable !== Boolean(meta?.isIndexable)) {
      dispatch(seoSlice.actions.setIsIndexable(isIndexable));
    }
  }, [isIndexable, meta]);

  useEffect(() => {
    if (!shopByStore && language && countryId && storeId) {
      dispatch(
        //@ts-ignore
        fetchHomepageSection(language, countryId, storeId, "shopByStore")
      );
    }
  }, [shopByStore, storeId, isIndexable]);

  useEffect(() => {
    return () => {
      dispatch(seoSlice.actions.removeMetaData());
    };
  }, [location]);

  const metaTags = useMemo(
    () =>
      getMetaTags({
        title,
        description,
        image,
        isIndexable,
        siteName: defaultTitle,
        pathname: location.pathname
      }),
    [title, description, location.pathname]
  );

  return (
    <div id={id} className={className}>
      <Helmet
        htmlAttributes={{
          lang: language,
          itemtype: "http://schema.org/WebPage"
        }}
        title={title}
        meta={metaTags}
      >
        {meta?.canonicalUrl && (
          <link rel="canonical" href={meta?.canonicalUrl} />
        )}

        {isIndexable && (
          <script type="application/ld+json">
            {JSON.stringify(logoStructuredData)}
          </script>
        )}
      </Helmet>

      {children}
    </div>
  );
};

export default Page;
