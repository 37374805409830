import get from "lodash/get";
import { getVisitorId } from "@/util";
import { pushGaProductClick } from "./utils";
import { GA_EVENTS } from "./events";

class CommonAnalyticService {
  trackPageBrowse = url => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.pageBrowse,
      URL: url
    });
  };

  trackNewsletterSubscription = trackData => {
    const email = get(trackData, "email", "");
    const customerId = get(trackData, "customerId", "");
    const userType = get(trackData, "userType", "");

    pushGaProductClick.pushGa({
      event: GA_EVENTS.subscribeNewsletter,
      newsletterSubscription: {
        email,
        customerId,
        userType
      }
    });
  };

  trackLeadSubmitted = trackData => {
    const email = get(trackData, "email", "");
    const totalAmount = get(trackData, "totalAmount", "");
    pushGaProductClick.pushGa({
      event: GA_EVENTS.leadSubmitted,
      email,
      total_amount: totalAmount
    });
  };

  trackHomePageStatus = email => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.homePage,
      email
    });
  };

  trackHomePageEvent = () => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.homePage,
      email: ""
    });
  };

  trackViewCategory = catalogueCategory => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.viewCategory,
      catalogueCategory
    });
  };

  trackViewSubCategory = trackData => {
    const parentCategory = get(trackData, "parentCategory", "");
    const category = get(trackData, "category", "");
    const subCategory = get(trackData, "subCategory", "");
    pushGaProductClick.pushGa({
      event: GA_EVENTS.viewSubcategory,
      parentCategory,
      category,
      subCategory,
      page: "plp"
    });
  };

  trackPageType = ({ url, type }) => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.pageType,
      url,
      type
    });
  };

  trackCountryChange = ({
    country_id,
    customer_id,
    language,
    country_name,
    currency
  }) => {
    const userId = customer_id
      ? { customer_id }
      : { visitor_id: getVisitorId() };
    pushGaProductClick.pushGa({
      event: GA_EVENTS.countryChange,
      ...userId,
      country_id,
      language,
      country_name,
      currency
    });
  };
}

export default CommonAnalyticService;
