import { useEffect } from "react";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import { FILTER_TYPES } from "../constants";

const useTrackFiltersByTagList = (
  tagList,
  selectedFilters,
  setSelectedFilters,
  updateSelectedFilters
) => {
  useEffect(() => {
    if (!isEmpty(tagList)) {
      const filtersToAdd = tagList.filter(tag => {
        return !selectedFilters[tag.filter.queryParam];
      });
      const filtersToRemove = Object.keys(selectedFilters).filter(
        key =>
          !tagList.find(tag => tag.filter.queryParam === key) &&
          selectedFilters[key].type !== FILTER_TYPES.PRICE
      );
      const groupedFilters = groupBy(filtersToAdd, "filter.queryParam");
      Object.keys(groupedFilters).forEach(key => {
        updateSelectedFilters({
          ...groupedFilters[key][0].filter,
          selectedOptions: groupedFilters[key]
        });
      });
      filtersToRemove.forEach(filterToRemove => {
        setSelectedFilters(currentFilters => {
          const { [filterToRemove]: toRemove, ...newFilters } = currentFilters;
          return newFilters;
        });
      });
    }
  }, [tagList]);
};

export default useTrackFiltersByTagList;
