import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useLocation, matchPath } from "react-router-dom";
import has from "lodash/has";

import { selectSettings } from "@/util/selectors";
import { useAppSelector } from "../redux/store";

import {
  DEFAULT_COUNTRY_SHORT,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LANGUAGE,
  LANGUAGE_ROUTE_KEY_MAP,
  DEFAULT_IS_ARABIC_ENABLED
} from "../constants";

export const useRouteSlug = (): string => {
  const { pathname } = useLocation();

  const matchResult = useMemo(
    () =>
      matchPath<{ routeSlug: string }>(pathname, {
        path: "/:routeSlug/"
      }),
    [pathname]
  );

  if (!matchResult) return "en-ae";

  return matchResult.params.routeSlug;
};

export const useLanguage = (): Language => {
  const routeSlug = useRouteSlug();

  const language = routeSlug?.split("-")[0] as Language;

  return language || DEFAULT_LANGUAGE;
};

export const useLongLanguage = (): LanguageLong => {
  const language = useLanguage();

  return LANGUAGE_ROUTE_KEY_MAP[language];
};

export const useCountryShort = (): string => {
  const routeSlug = useRouteSlug();

  const countryShort = routeSlug?.split("-")[1];

  return countryShort || DEFAULT_COUNTRY_SHORT;
};

export const useIsRTL = (): boolean => {
  const language = useLanguage();

  return language === "ar";
};

export const useCountryId = (): number => {
  const countryId = useAppSelector(state => state.common.settings.countryId);

  return countryId;
};

export const useCurrency = (): Currency => {
  const currency =
    useAppSelector(state => state.common.settings?.currencyCode) ||
    DEFAULT_CURRENCY_CODE;

  return currency;
};

export const useIsArabicEnabled = (): boolean => {
  const configs = useAppSelector(selectSettings);

  return has(configs, "default.isArabicEnabled")
    ? configs.default?.isArabicEnabled
    : DEFAULT_IS_ARABIC_ENABLED;
};

/**
 * This hook is used to detect outside click events on a given element. The most common use case for this hook is to close a dropdown when a user clicks outside of it.
 * @param onEventOutsideDetection - The callback function to be called when an outside click event is detected.
 * @param events - The events to be listened to. Defaults to ["click", "touchstart"].
 */

export const useOutsideEventDetector = <T extends HTMLElement>(
  onEventOutsideDetection: () => void,
  events = ["click", "touchstart"]
): MutableRefObject<T | null> => {
  const eventContainerRef = useRef<T | null>(null);

  useEffect(() => {
    if (!eventContainerRef.current) return;

    const eventHandler = e => {
      if (eventContainerRef.current?.contains(e.target)) return;
      onEventOutsideDetection?.();
    };

    events.forEach(type => {
      document.addEventListener(type, eventHandler);
    });

    return () => {
      events.forEach(type => {
        document.removeEventListener(type, eventHandler);
      });
    };
  }, [onEventOutsideDetection]);

  return eventContainerRef;
};

export const useIsLoggedIn = (): boolean => {
  const userLoggedIn = useAppSelector(state => state.authReducer.userLoggedIn);

  return userLoggedIn;
};
