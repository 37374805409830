import React, { FC } from "react";
import PriceSlab from "../../../components/price/priceSlab";
import { Product } from "../../../services/productService";
import "./style.scss";

export const ItemDescription: FC<{ item: Product }> = ({ item }) => {
  const showPriceSlab =
    item.isPriceAvailableInUserCountry && Boolean(item?.price);
  const brandName = item.brand?.label;
  const title = item.title?.label;

  return (
    <div className="item_description_wrapper">
      <div className="view_content">
        {brandName && <h5 className="pdp_brand">{brandName}</h5>}
        {title && <h1 className="product_title">{title}</h1>}
        {showPriceSlab && (
          <PriceSlab
            product={item}
            retailPriceVisible
            discountLabelVisible={false}
            priceLabelVisible
          />
        )}
      </div>
    </div>
  );
};
