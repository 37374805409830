import { fetchWebApi } from "../../webapis/apiResource";
import { BEConfig } from "../../config/env";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import {
  HANDLE_GRID_CHANGE,
  TOGGLE_QUICKLOOK_VIEW,
  TOGGLE_APP_ONLY_ITEM_MODAL,
  SET_INITIAL_STATE,
  HANDLE_GENERATED_URL
} from "../constants";

import { StorageService } from "../../services";
import { setViewGrid } from "@/util";

export const handleGridChange = (gridValue: number) => {
  setViewGrid(gridValue);

  return {
    type: HANDLE_GRID_CHANGE,
    gridValue
  };
};

export const toggleQuicklookView = showQuicklookModal => ({
  type: TOGGLE_QUICKLOOK_VIEW,
  showQuicklookModal
});
export const toggleAppOnlyItemModal = ({ showAppOnlyItemModal, product }) => ({
  type: TOGGLE_APP_ONLY_ITEM_MODAL,
  showAppOnlyItemModal,
  product
});

export const setInitialState = () => ({ type: SET_INITIAL_STATE });

export const handleGeneratedUrls = ({ data }) => ({
  type: HANDLE_GENERATED_URL,
  data
});

export const fetchGeneratedUrls = showLoader => {
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.generatedUrlHandle}`;
  return dispatch => {
    showLoader && dispatch(showLoadingIndicator());
    return fetchWebApi(null, apiURL)
      .then(response => {
        showLoader && dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(handleGeneratedUrls(response.data));
        }
        return response;
      })
      .catch(error => {
        showLoader && dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
