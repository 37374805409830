import React, { FC, createContext, useState, useEffect } from "react";
import cn from "clsx";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import difference from "lodash/difference";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import FilterCategoryLeaf from "./filterCategoryLeaf";
import FilterCategoryDropDownList from "./filterCategoryDropDownList";
import FilterWrapperElement from "./filterWrapperElement";
import { ApplyButton } from "./applyButton";
import { getDeepSelectedCategory } from "@/utils";
import { CategoryFilterOption, FilterType, GeneralFacet } from "../types";

type FilterCategoryPropsType = {
  filter: Omit<GeneralFacet & { data: CategoryFilterOption[] }, "sortOrder">;
  setToggledFilter: (isToggle: any) => void;
  toggledFilter: FilterType;
  onApplyButtonClick: (...args) => void;
};

export const selectedCategoryFilterContext = createContext<{
  selectedOptions: any;
  onSelection: (...args) => void;
  selectedDropDown: { [val: string]: string };
  setSelectedDropDown: (...args) => void;
}>({
  selectedOptions: [],
  onSelection: (...args) => {},
  selectedDropDown: {},
  setSelectedDropDown: (...args) => {}
});

export const RootFilterContent: FC<{
  filterContent: CategoryFilterOption;
}> = ({ filterContent }) => {
  const isLeaf = isEmpty(filterContent.children);

  if (isLeaf) {
    return <FilterCategoryLeaf option={filterContent} />;
  }
  return <FilterCategoryDropDownList filterContent={filterContent} />;
};

const FilterCategoryMob: FC<FilterCategoryPropsType> = ({
  filter,
  setToggledFilter,
  toggledFilter,
  onApplyButtonClick
}) => {
  const [screenHeight, setScreenHeight] = useState(0);

  const [selectedByUser, setSelectedByUser] = useState<any>([]);
  const [selectedByResponse, setSelectedByResponse] = useState<any>(null);
  const [selectedDropDown, setSelectedDropDown] = useState<{
    [val: string]: string;
  }>({});

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  const { t } = useTranslation("productListing");

  useEffect(() => {
    const selectedCategoryByResponse = getDeepSelectedCategory(filter);
    setSelectedByUser(selectedCategoryByResponse);
    setSelectedByResponse(selectedCategoryByResponse);
  }, [filter]);

  const openCategoriesClickHandler = () => {
    const newFilter = {
      ...filter,
      queryParam: filter.data[0].queryParam,
      data: filter.data[0]
    };
    setToggledFilter(newFilter);
  };

  const onFilterOptionClick = ({ option, isLeaf = false }, event) => {
    event?.stopPropagation();

    let options = [];

    const alreadyAdded = optionArray =>
      optionArray.find(selectedOption => selectedOption.key === option.key);

    if (isLeaf) {
      const filteredOptions = selectedByUser.filter(
        selectedOption => selectedOption.queryParam === option.queryParam
      );
      const isAlreadyAdded = alreadyAdded(filteredOptions);
      if (isAlreadyAdded) {
        options = selectedByUser.filter(item => item.key !== option.key);
      } else {
        options = [...selectedByUser, option];
      }

      const getDeepSelectedOptions = (option, selectedByUser) => {
        if (isEmpty(option.children)) {
          return [];
        }

        if (
          selectedByUser.find(
            selectedOption => selectedOption.key === option.key
          )
        ) {
          return [option];
        }

        const selectedChildren = option.children.filter(child =>
          selectedByUser.find(
            selectedOption => selectedOption.key === child.key
          )
        );
        let selected = [];
        selected.push(
          !isEmpty(selectedChildren)
            ? selectedChildren
            : option.children.flatMap(child =>
                getDeepSelectedOptions(child, selectedByUser)
              )
        );
        return selected.flat();
      };

      const getOptionParent = (option, selectedByUser) => {
        if (isEmpty(selectedByUser)) {
          return null;
        }

        return selectedByUser.find(
          selection => !isEmpty(getDeepSelectedOptions(selection, [option]))
        );
      };
      const selectedParent = getOptionParent(option, selectedByUser);

      if (!isAlreadyAdded && selectedParent) {
        setSelectedByUser([
          ...difference(selectedByUser, [selectedParent]),
          option
        ]);
        return;
      }
      const selectedOptionsToRemove =
        !isAlreadyAdded &&
        isLeaf &&
        option.children &&
        //@ts-ignore
        getDeepSelectedOptions(option, selectedByUser);

      if (!isEmpty(selectedOptionsToRemove)) {
        const diff = selectedByUser.filter(
          optionByUser =>
            !selectedOptionsToRemove.find(
              optionToRemove => optionByUser.key === optionToRemove.key
            )
        );

        setSelectedByUser([...diff, option]);
        return;
      }

      setSelectedByUser(options);
    } else {
      setSelectedByUser(alreadyAdded(selectedByUser) ? [] : [option]);
    }
  };

  const onBackButtonClick = e => {
    e.stopPropagation();
    setToggledFilter({});
    setSelectedByUser([]);
  };

  const clearedSelectedResponseOptions = selectedByResponse
    ? [
        {
          ...filter,
          queryParam: selectedByResponse[0]?.queryParam,
          selectedOptions: []
        }
      ]
    : [];

  const onFilterClearClickHandler = () => {
    if (selectedByUser.length > 0) {
      setSelectedByUser([]);
    }
    if (selectedByResponse.length > 0) {
      setSelectedByUser([]);
      onApplyButtonClick([...clearedSelectedResponseOptions]);
    }
  };

  const onApplyBtnClick = async () => {
    const groupedFilters = groupBy(selectedByUser, "queryParam");

    await onApplyButtonClick([
      ...clearedSelectedResponseOptions,
      ...(!isEmpty(selectedByUser)
        ? Object.keys(groupedFilters).map(key => ({
            ...filter,
            queryParam: key,
            selectedOptions: groupedFilters[key]
          }))
        : [])
    ]);

    setToggledFilter({});
  };

  const selectedCategoryLabel = data => {
    let historyLabels = [];

    const traverseAndCheckSelection = (arr, parentLabel = "") => {
      arr.forEach(({ isSelected, children, extraLabel, label }) => {
        const currentLabel = name =>
          parentLabel ? `${parentLabel} > ${name}` : name;

        if (isSelected) {
          if (children) {
            const selectedChildren = children.filter(child => child.isSelected);
            if (isEmpty(selectedChildren)) {
              historyLabels.push(currentLabel(extraLabel));
            } else {
              traverseAndCheckSelection(children, currentLabel(label));
            }
          } else {
            historyLabels.push(currentLabel(label));
          }
        }
      });
    };

    traverseAndCheckSelection(data);

    return historyLabels.join(", ");
  };

  const isCurrentToggledFilter =
    toggledFilter && toggledFilter.queryParam === filter?.data[0]?.queryParam;

  return filter.label ? (
    <h4
      className={cn(
        "filter_title",
        isCurrentToggledFilter ? "filter_open" : "filter_close_inner"
      )}
      onClick={openCategoriesClickHandler}
    >
      <span>
        {filter.label}
        {selectedCategoryLabel(filter.data) && (
          <strong>{selectedCategoryLabel(filter.data)}</strong>
        )}
      </span>
      {isCurrentToggledFilter && (
        <div
          className={"filter_content openLeft"}
          onClick={e => e.stopPropagation()}
        >
          <FilterWrapperElement
            iconClassName="mobile_back_icon"
            onIconClick={onBackButtonClick}
            filterTitle={filter.label}
            onClearBtnClick={onFilterClearClickHandler}
            isHeaderVisible={isCurrentToggledFilter}
          >
            <Scrollbars
              autoHeight
              autoHeightMin={55}
              autoHeightMax={`calc(${screenHeight}px - 100px)`}
            >
              <div className="mob_category_container">
                <ul className={"hierarchical_categories2_item"}>
                  <selectedCategoryFilterContext.Provider
                    value={{
                      selectedOptions: selectedByUser,
                      onSelection: onFilterOptionClick,
                      selectedDropDown,
                      setSelectedDropDown
                    }}
                  >
                    {filter.data.map(option => (
                      <RootFilterContent
                        key={option.key}
                        filterContent={option}
                      />
                    ))}
                  </selectedCategoryFilterContext.Provider>
                </ul>
              </div>
            </Scrollbars>
            <ApplyButton
              onButtonClick={onApplyBtnClick}
              buttonText={t("apply")}
            />
          </FilterWrapperElement>
        </div>
      )}
    </h4>
  ) : null;
};

export default FilterCategoryMob;
