import get from "lodash/get";
import { pushGaProductClick, createObjForGA } from "./utils";
import { GA_EVENTS } from "./events";

class ProductAnalyticService {
  trackProductSearch = trackData => {
    const text = get(trackData, "text", "");
    const itemCount = get(trackData, "itemCount", "");
    pushGaProductClick.pushGa({
      event: GA_EVENTS.productSearch,
      search_keyword: text,
      item_count: itemCount
    });
  };

  trackProductDetailImpressions = trackData => {
    const item = get(trackData, "item", {});
    const recentlyViewed = get(trackData, "recentlyViewed", []);
    let impressionObject = createObjForGA.multipleItemsImpression({
      item,
      page: "productDetails",
      recentlyViewed
    });
    const ecommerce = get(impressionObject, "ecommerce", {});
    const outerObj = get(impressionObject, "outerObj", {});
    const updatedRecentlyViewed = get(
      impressionObject,
      "updatedRecentlyViewed",
      {}
    );
    pushGaProductClick.pushGaGTMProductDetailsImpressions(
      ecommerce,
      outerObj,
      updatedRecentlyViewed
    );
  };

  trackProductListingDetailImpressions = trackData => {
    const item = get(trackData, "item", {});
    const title = get(trackData, "title", "");
    const category = get(trackData, "category", "");
    const { ecommerce, outerObj } = createObjForGA.multipleItemsImpression({
      item,
      page: title,
      category
    });
    pushGaProductClick.pushGaGTMProductClick1(ecommerce, outerObj);
  };

  trackMoveToProductDetail = trackData => {
    const item = get(trackData, "item", {});
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce({ item });
    pushGaProductClick.pushGaProductClick1(ecommerce, outerObj);
    pushGaProductClick.pushGaGTMProductClick1(ecommerce, outerObj);
  };

  trackProductDetails = trackData => {
    const item = get(trackData, "response.data", {});
    const productUrl = get(trackData, "location.pathname", "");
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce({
      item,
      productUrl
    });
    pushGaProductClick.pushGaProductClick2(ecommerce, outerObj);
    pushGaProductClick.pushGaProductClick2(ecommerce, {
      ...outerObj,
      page: "productView"
    });
  };

  trackProductPurchase = trackData => {
    const order = get(trackData, "order", {});
    const checkout = get(trackData, "checkout", {});
    const orderItems = get(order, "items", []);
    const totalAmount = get(order, "transactionAmount", 0);
    const paymentMode = get(order, "paymentMode", []);
    const coupen = get(order, "discountType", "");
    const totalDiscount = get(order, "transactionDiscount", 0);
    const voucherCode = get(checkout, "voucherCode", "");
    const itemLength = get(order, "items.length", 0);
    const deliveryType = get(order, "deliveryType", "");

    const { ecommerce, outerObj, actionField } =
      createObjForGA.multipleNetCoreItems(orderItems, order);

    pushGaProductClick.pushGaPurchase(
      ecommerce,
      {
        ...outerObj,
        total_amount: totalAmount,
        mode_of_payment: paymentMode,
        coupon_applied: coupen,
        coupon: coupen,
        total_discount: totalDiscount
      },
      {
        ...actionField,
        total_amount: totalAmount,
        mode_of_payment: paymentMode,
        coupon_applied: coupen,
        coupon: voucherCode,
        affiliation: "BFL Online Store",
        total_discount: totalAmount,
        total_items: itemLength,
        shipping_option: deliveryType,
        payment_type: paymentMode
      }
    );
  };

  trackContentListing = (trackData: {
    item: any;
    title: string;
    category: string;
    productListCount: number;
    position: number;
  }) => {
    const item = get(trackData, "item", []);
    const title = get(trackData, "title", "");
    const category = get(trackData, "category", "");
    const position = get(trackData, "position", 0);
    const productListCount = get(trackData, "productListCount", 1);
    const { ecommerce, outerObj } = createObjForGA.multipleItemsImpression({
      item,
      page: "product listing page",
      category,
      productListCount,
      position
    });

    pushGaProductClick.pushNTProductListing(ecommerce, outerObj);
  };

  trackProductQuickLook = ({ id, title, brandName }) => {
    pushGaProductClick.pushGa({
      event: "quickLook",
      productId: id,
      title,
      brandName
    });
  };
}

export default ProductAnalyticService;
