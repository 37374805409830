export const hideMegaMenu = e => {
  let activeLi = "active-li";
  let parent = e.currentTarget.parentElement;

  while (!parent.classList.contains(activeLi)) {
    parent = parent.parentElement;
  }
  if (parent.classList.contains(activeLi)) {
    parent.classList.remove(activeLi);
    parent.parentElement.nextElementSibling.classList.remove("active");
  }

  return null;
};

export const highlight = e => {
  const highlighted = "highlighted";
  const tlm = "top-level-menu";
  const highlightedEl = document.querySelector(`.${highlighted}`);
  let parent = e.currentTarget.parentElement;

  while (!parent.classList.contains(tlm)) {
    parent = parent.parentElement;
  }
  if (parent.classList.contains(tlm)) {
    highlightedEl && highlightedEl.classList.remove(highlighted);
    parent.querySelector(".arrow").classList.add(highlighted);
  }

  return null;
};

export const getElementHeight = domElement => {
  if (!domElement) return null;
  const { height } = domElement.getBoundingClientRect();
  return height;
};

export const getChildrenHeight = parentDomElement => {
  if (!parentDomElement) return null;
  return Array.from(parentDomElement.children)
    .slice(0, 2)
    .reduce((acc, child) => {
      acc += getElementHeight(child);
      return acc;
    }, 0);
};

export const removeScriptFromBody = script => {
  document.body.removeChild(script);
};

export const addScriptToBody = (() => {
  let scriptState = {};

  return (
    src: string,
    onLoad: (...args) => void = null,
    preventReAdding?: boolean
  ) => {
    const scriptTags =
      preventReAdding && document.querySelectorAll(`script[src="${src}"]`);

    if (scriptTags?.length) {
      if (scriptState[src].loaded) {
        return false;
      } else {
        scriptTags.forEach(removeScriptFromBody);
      }
    }
    if (!scriptState[src]) {
      scriptState[src] = { loaded: false, callbacks: [] };
    }

    scriptState[src].callbacks.push(onLoad);

    const script = document.createElement("script");
    script.src = src;
    script.onload = (...args) => {
      if (scriptState[src].loaded) {
        return;
      }
      scriptState[src].callbacks.forEach(callback => {
        callback?.(...args);
      });
      scriptState[src].loaded = true;
    };
    document.body.appendChild(script);
    return script;
  };
})();

export const scrollParentToChild = (parent, child) => {
  const parentRect = parent.getBoundingClientRect();
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };

  const childRect = child.getBoundingClientRect();
  const isViewable =
    childRect.top >= parentRect.top &&
    childRect.bottom <= parentRect.top + parentViewableArea.height;

  if (!isViewable) {
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      parent.scrollTop += scrollTop;
    } else {
      parent.scrollTop += scrollBot + 20;
    }
  }
};
