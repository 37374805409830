import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import get from "lodash/get";
import qs from "query-string";
import { ErrorBoundary } from "react-error-boundary";

import { getBaseUrl } from "@/util";
import {
  setPrevModeStorage,
  removePrevModeStorage
} from "@/util/browserStorage";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setOTPLoginFlowBFL } from "@/redux/actions/homepage.action";
import {
  fetchUserAndLoadData,
  showPrevMode
} from "@/redux/actions/authConfig.action";
import { selectOTPLoginFlowBFL } from "@/util/selectors";
import AuthAnaltyicsEvent from "@/services/analytics/main/authentication";
import { useLanguage, useRouteSlug } from "@/hooks";
import Header from "@/components/header";
import Footer from "@/components/footer/footer";
import ScrollToTop from "@/components/scroll-to-top";
import PreviewMode from "@/components/preview-mode";
import AppContainer from "./appContainer";
import View from "@/components/common/view";
import MobileHeader from "@/components/header/mobile-header";
import ErrorFallback from "@/components/errorFallback";
import { routes } from "./routes";
import CommonAnalyticsEvent from "@/services/analytics/main/common";

const pagesWithoutHeader = ["checkout", "payment-error", "payment-processing"];
const hideInMobileValues = ["p", "my-cart", "wishlist"];
const entryPageRoute = "/";

const AuthContent = () => {
  const [footerSpace, setFooterSpace] = useState(false);
  const [hideInMobile, setHideInMobile] = useState(false);
  const language = useLanguage();
  const userLoggedIn = useAppSelector(state => state.authReducer.userLoggedIn);
  const settings = useAppSelector(state => state.common.settings);
  const isPreviewMode = useAppSelector(state => state.authReducer.showPreview);
  const routeSlug = useRouteSlug();
  const otpLoginFlowBFL = useAppSelector(state => selectOTPLoginFlowBFL(state));

  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const dispatch = useAppDispatch();

  const getHideHeaderValue = () =>
    pathname === entryPageRoute ||
    pagesWithoutHeader.some(page => pathname.includes(page));

  const [hideHeader, setHideHeader] = useState(getHideHeaderValue());

  useEffect(() => {
    const { prevMode } = qs.parse(search);

    if (prevMode) {
      setPrevModeStorage(prevMode);

      const newUrl = qs.stringifyUrl({
        url: pathname,
        query: {}
      });

      history.push(newUrl);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (settings.countryId && userLoggedIn) {
        const response = await dispatch(
          fetchUserAndLoadData({ keepDeliverySettings: true })
        );
        const isOTPLoginFlowBFL =
          otpLoginFlowBFL && response.status === 200 && response.data.success;

        if (isOTPLoginFlowBFL) {
          dispatch(setOTPLoginFlowBFL(false));
          AuthAnaltyicsEvent.emailSignIn({
            loginFrom: "email",
            responseData: response.data
          });
        }
        const customerId = get(response, "data.userProfile.customerId", "");
        CommonAnalyticsEvent.trackSessionStart(customerId);
      }
    };

    fetchData();
  }, [settings.countryId, userLoggedIn]);

  useEffect(() => {
    const pathSegments = pathname.split("/");
    const isHideHeader = getHideHeaderValue();
    const isChangeFooterSpace = pathname.includes("product-detail");
    const isHideInMobile = pathSegments.some(segment =>
      hideInMobileValues.includes(segment)
    );

    if (isHideHeader !== hideHeader) {
      setHideHeader(isHideHeader);
    }

    if (isChangeFooterSpace !== footerSpace) {
      setFooterSpace(isChangeFooterSpace);
    }

    if (isHideInMobile !== hideInMobile) {
      setHideInMobile(isHideInMobile);
    }
  }, [pathname]);

  const exitPrevMode = () => {
    dispatch(showPrevMode(false));
    removePrevModeStorage(document.domain);
    history.push(getBaseUrl({ language, settings }) + "/");
  };

  let redirectPaths = [];
  if (userLoggedIn) {
    redirectPaths = ["/login", "/sign-up"];
  }

  const switchRoutes = routes.map(route => {
    return (
      <Route
        exact={!!route.exact}
        path={route.path}
        component={route.component}
        key={route.path}
      >
        {redirectPaths.some(path => route.path.includes(path)) && (
          <Redirect to={`/${routeSlug}/`} />
        )}
      </Route>
    );
  });

  return (
    <ScrollToTop>
      {isPreviewMode && <PreviewMode exitPrevMode={exitPrevMode} />}
      {!hideHeader && (
        <>
          <View.desktop>
            <Header />
          </View.desktop>

          <View.mobile>
            <MobileHeader showOnlyBanner={!hideInMobile} />
          </View.mobile>
        </>
      )}

      <AppContainer>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>{switchRoutes}</Switch>
        </ErrorBoundary>
      </AppContainer>
      {!hideHeader && (
        <Footer hideInMobile={hideInMobile} footerSpace={footerSpace} />
      )}
    </ScrollToTop>
  );
};

export default AuthContent;
