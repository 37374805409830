import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import useSwiperScript from "@/hooks/useSwiperScript";
import { useIsRTL } from "@/hooks";
import { SwiperOptions } from "swiper/types/swiper-options";
import { IconType } from "../types";
import "./style.scss";

const FilteredBy = ({ tagList, onTagRemove, onClearAll }) => {
  const { t } = useTranslation("productListing");
  const [swiper, setSwiper] = useState(null);
  const [isNextButton, setIsNextButton] = useState(false);
  const [isPrevButton, setIsPrevButton] = useState(false);
  const swiperRef = useRef(null);
  const isRTL = useIsRTL();

  const loadedScriptStatus = useSwiperScript();

  useEffect(() => {
    if (
      swiperRef.current &&
      tagList?.length &&
      (loadedScriptStatus || window.Swiper)
    ) {
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      setSwiper(swiper);
      swiper.on("activeIndexChange", e => {
        setIsNextButton(!e.isEnd);
        setIsPrevButton(!e.isBeginning);
      });
      swiper.on("reachBeginning", e => {
        setIsPrevButton(false);
      });
      swiper.on("reachEnd", e => {
        setIsNextButton(false);
      });
      setIsNextButton(!swiper.isEnd);
      setIsPrevButton(!swiper.isBeginning);
      isRTL && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, tagList, loadedScriptStatus]);

  if (isEmpty(tagList)) return null;

  const swiperParams: SwiperOptions = {
    freeMode: true,
    grabCursor: true,
    slidesPerView: "auto",
    slidesPerGroup: 3,
    spaceBetween: 14,
    threshold: 8,
    navigation: true
  };

  return (
    <div className="selected_filter my-4 mx-auto">
      <div className="container px-0">
        <ErrorBoundary fallback={null}>
          <ul className="d-flex position-relative">
            <div className="swiper" ref={swiperRef}>
              <div className="swiper-wrapper">
                {tagList.map((tag, index) => (
                  <li
                    className="filter-container swiper-slide"
                    key={`${tag.key}${index}`}
                  >
                    {tag.icon && getTagIcon(tag.icon)}
                    <span className="filter-content">{tag.label}</span>
                    <span
                      className="cross-icon"
                      onClick={() => onTagRemove(tag)}
                    />
                  </li>
                ))}
                <li
                  className="filter-container clear-all-btn swiper-slide"
                  onClick={onClearAll}
                >
                  <span className="filter-content">{t("clearALL")}</span>
                  <span className="cross-icon" />
                </li>
              </div>
            </div>
            {swiper && (
              <>
                {isPrevButton && (
                  <button
                    className="swiper-button-prev"
                    onClick={() => swiper.slidePrev()}
                  ></button>
                )}
                {isNextButton && (
                  <button
                    className="swiper-button-next"
                    onClick={() => swiper.slideNext()}
                  ></button>
                )}
              </>
            )}
          </ul>
        </ErrorBoundary>
      </div>
    </div>
  );
};

const getTagIcon = (icon: { type: IconType; value: string }) => (
  <span
    className="filter-icon"
    style={
      icon.type === IconType.COLOR
        ? { backgroundColor: icon.value }
        : {
            backgroundImage: `url(${icon.value})`,
            backgroundSize: "contain"
          }
    }
  />
);

export default FilteredBy;
