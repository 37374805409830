import React from "react";
import classnames from "clsx";
import { encodeSpace } from "@/util";
import { SYTE_DISCOVERY_LOCATION } from "@/constants";
import withSyteApiStatus from "@/hocs/withSyteApiStatus";
import SyteDiscoveryListIcon from "@/images/syte-discovery-listing.svg";
import SyteDiscoveryListMobileIcon from "@/images/syte-discovery-listing-mobile.svg";
import SyteDiscoveryPDPOrangeWTextIcon from "@/images/syte-discovery-product-orange-w-text.svg";
import SyteDiscoveryPDPOrangeMobileIcon from "@/images/syte-discovery-product-orange-mobile.svg";
import SyteDiscoveryPDPOutOfStockMobileIcon from "@/images/syte-discovery-out_of-stock-mobile.svg";
import "./style.scss";

const SyteDiscoveryButton = withSyteApiStatus(
  ({
    productImgPath,
    sku,
    placement = "main-image",
    location,
    mobile = false,
    className,
    text,
    details,
    handleClick,
    syteAppIsReady,
    filters,
    style
  }) => {
    let iconImage;
    switch (location) {
      case SYTE_DISCOVERY_LOCATION.LISTING:
        if (mobile) {
          iconImage = SyteDiscoveryListMobileIcon;
        } else {
          iconImage = SyteDiscoveryListIcon;
          className += " hover";
        }
        break;
      case SYTE_DISCOVERY_LOCATION.OUT_OF_STOCK_SIZE:
        iconImage = mobile
          ? SyteDiscoveryPDPOutOfStockMobileIcon
          : SyteDiscoveryListMobileIcon;
        break;
      case SYTE_DISCOVERY_LOCATION.PDP:
      default:
        iconImage = mobile
          ? SyteDiscoveryPDPOrangeMobileIcon
          : SyteDiscoveryPDPOrangeWTextIcon;
        break;
    }

    const clickHandler = e => {
      e.preventDefault();
      if (handleClick && details) {
        handleClick(details);
      }
    };

    if (!Boolean(syteAppIsReady)) return null;

    return (
      <button
        className={classnames(
          "syte-discovery",
          "btn btn-link syte-discovery-btn",
          className
        )}
        data-image-src={encodeSpace(productImgPath)}
        data-sku={sku}
        data-placement={placement}
        onClick={clickHandler}
        {...(filters && { "data-filters": JSON.stringify(filters) })}
        {...(style && { style })}
      >
        <img src={iconImage} alt="search similar" />
        {text && <span className="syte-discovery-btn-text">{text}</span>}
      </button>
    );
  }
);

export default SyteDiscoveryButton;
