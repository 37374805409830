import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import lowerCase from "lodash/lowerCase";

import AnalyticService from "@/services/analytic-service";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import PlpAnalytics from "@/services/analytics/main/plp";
import { setPageType } from "@/redux/actions/page.action";
import { ProductsListingPageData } from "@/routes/productListing/types";

const useAnalyticPageBrowseTracking = () => {
  const location = useLocation();

  useEffect(() => {
    AnalyticService.common.trackPageBrowse(location.pathname);
  }, [location]);
};

const usePageTypeTracking = ({ name }: { name?: string }) => {
  const pageData = useAppSelector(state => state.dynamicPage.data);
  const authData = useAppSelector(state => state.authReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!name) return;

    const title = pageData?.meta?.heading1?.label || name || undefined;
    const type = name;
    const isLoggedIn = !!authData.userLoggedIn;

    const url = pageData?.url;

    CommonAnalyticsEvent.viewPage({
      type,
      title,
      customerId: authData.customerId?.toString() || undefined,
      userLoggedIn: isLoggedIn,
      url
    });

    if (type) {
      dispatch(setPageType(type));
    }

    if (pageData?.isBrandPage) {
      const { nbHits, en_title } = pageData?.data as ProductsListingPageData;
      PlpAnalytics.brandStock({
        brand_quantity: nbHits,
        brand_name: lowerCase(en_title)
      });
    }
  }, [name, pageData]);
};

export const usePageTracking = ({ name }: { name?: string }) => {
  usePageTypeTracking({ name });
  useAnalyticPageBrowseTracking();
};
