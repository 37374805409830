import React, { FC } from "react";
import { SYTE_DISCOVERY_LOCATION } from "@/constants";
import { useAppSelector } from "@/redux/store";
import { Product } from "@/services/productService";
import { selectCdnImageSettings } from "@/util/selectors";
import SyteDiscoveryButton from "@/components/syte/syteButton/syteDiscoveryButton";

const SyteDiscoveryButtonWrapper: FC<{
  item: Product;
  className: string;
  isMobile?: boolean;
  text?: string;
}> = ({ item, className, isMobile = false, text = "" }) => {
  const configCdnImagesSettings = useAppSelector(selectCdnImageSettings);

  return (
    <SyteDiscoveryButton
      sku={item.id}
      details={item}
      productImgPath={`${configCdnImagesSettings?.list}${item.captionImageURL}`}
      placement="product-listing"
      location={SYTE_DISCOVERY_LOCATION.LISTING}
      className={className}
      mobile={isMobile}
      text={text}
      // TODO: Handle click for analytic
      // handleClick={handleSyteSearch}
    />
  );
};

export default SyteDiscoveryButtonWrapper;
