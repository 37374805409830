import View from "@/components/common/view";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import isEmpty from "lodash/isEmpty";

import FlashSaleFilters from "../saleFiltersWeb";
import FilteredBy from "../filteredBy";
import PlpAnalytics from "@/services/analytics/main/plp";
import { SORT_LOCATION } from "@/services/analytics/main/plp/types";
import { handleFilterEvent } from "@/routes/productListing/analyticsHelper";
import useSelectedFilters from "@/routes/productListing/hooks/useSelectedFilters";
import { FILTER_QUERY_PARAMS, FILTER_TYPES } from "../constants";
import {
  Facet,
  FilterOption,
  FlashSaleFiltersProps,
  SelectedFiltersType
} from "../types";
import { SaleService } from "@/services";
import useProductListingFilters from "@/routes/productListing/hooks/useProductListingFilters";
import { SalePageDataContext } from "../../SaleWrapper";

const SaleWebFilteringSection: FC<FlashSaleFiltersProps> = ({ tabDetails }) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    {}
  );
  const { pageData, saleData, setSaleData, setSelectedFiltersQuery } =
    useContext(SalePageDataContext);
  const selectedFiltersData = useSelectedFilters(saleData);
  const listingFilters = useProductListingFilters(saleData);

  const bannerData = pageData.data;
  const pageContentValue = bannerData.slug;

  useEffect(() => {
    if (saleData && isEmpty(saleData.selectedFilters)) {
      setSelectedFilters({});
      setSelectedFiltersQuery({});
    }
  }, [saleData]);

  const updateSelectedFilters = useCallback(
    selected => {
      let updated: SelectedFiltersType = { ...selectedFilters };
      if (selected) {
        if (Array.isArray(selected)) {
          selected.forEach(item => {
            updated[item.queryParam] = item;
          });
        } else {
          updated[selected.queryParam] = selected;
        }
        setSelectedFilters(selected => ({ ...selected, ...updated }));
      } else {
        setSelectedFilters({});
      }

      return updated;
    },
    [selectedFilters]
  );

  const getUpdatedQuery = useCallback(
    (updatedSelection: SelectedFiltersType) => {
      const queryObject = Object.values(updatedSelection).reduce(
        (acc, filter) => {
          acc[filter.queryParam] = filter.selectedOptions.map(
            option => option.key
          );
          return acc;
        },
        {}
      );

      return queryObject;
    },
    [selectedFilters]
  );

  const onFilterChange = useCallback(
    async (query?: {}) => {
      setSelectedFiltersQuery(query);
      const { data } = await SaleService.getSaleSpecificTabData(
        tabDetails.slug,
        pageContentValue,
        query
      );
      setSaleData(data);
    },
    [tabDetails]
  );

  const onSelection = useCallback(
    selected => {
      const updatedSelection = updateSelectedFilters(selected);

      const query = getUpdatedQuery(updatedSelection);

      onFilterChange(query);

      if (selected.queryParam === FILTER_QUERY_PARAMS.SORT) {
        PlpAnalytics.sort({
          sort_by: selected?.selectedOptions?.[0]?.defaultValue?.toLowerCase(),
          sort_location: SORT_LOCATION.PRODUCT_LIST
        });
      } else {
        handleFilterEvent(selected, updatedSelection);
      }
    },
    [updateSelectedFilters, getUpdatedQuery, onFilterChange]
  );

  const tags = useMemo(
    () =>
      selectedFiltersData?.flatMap(filterData => {
        if (filterData.type === FILTER_TYPES.PRICE) return [];
        const { data: filterOptions, ...filterOnlyData } = filterData;
        return filterOptions.map(option => ({
          filter: { queryParam: option.queryParam, ...filterOnlyData },
          ...option
        }));
      }),
    [selectedFiltersData]
  );

  const onClearAll = useCallback(() => {
    updateSelectedFilters(null);
    onFilterChange({});
  }, [updateSelectedFilters]);

  const onTagRemove = useCallback(
    tag => {
      const newFilterSelection = {
        ...selectedFilters[tag.filter.queryParam],
        selectedOptions: selectedFilters[
          tag.filter.queryParam
        ].selectedOptions.filter(option => option.key !== tag.key)
      };
      const updatedSelection = updateSelectedFilters(newFilterSelection);
      const query = getUpdatedQuery(updatedSelection);
      onFilterChange(query);
    },
    [updateSelectedFilters]
  );

  return (
    <View.desktop>
      <SelectedFiltersContext.Provider
        value={{
          selectedFilters,
          setSelectedFilters,
          updateSelectedFilters,
          onSelection
        }}
      >
        <FlashSaleFilters filters={listingFilters} />
      </SelectedFiltersContext.Provider>
      <FilteredBy
        tagList={tags}
        onTagRemove={onTagRemove}
        onClearAll={onClearAll}
      />
    </View.desktop>
  );
};

export const SelectedFiltersContext = createContext<{
  selectedFilters: SelectedFiltersType;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Facet & {
        selectedOptions: FilterOption[];
      };
    }>
  >;
  updateSelectedFilters: (selected?: any) => {
    [key: string]: Facet & {
      selectedOptions: FilterOption[];
    };
  };
  onSelection: (...args) => void;
}>(null);

export default SaleWebFilteringSection;
