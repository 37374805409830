import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import useBodyScrollability from "../../../hooks/useBodyScrollability";
import {
  FILTER_QUERY_PARAMS,
  FILTER_TYPES
} from "@/routes/productListing/constants";
import PlpAnalytics from "@/services/analytics/main/plp";
import { SORT_LOCATION } from "@/services/analytics/main/plp/types";
import { handleFilterEvent } from "@/routes/productListing/analyticsHelper";
import FilterSidebar from "./filtersSidebar";
import FiltersPanel from "./filtersPanel";
import useTrackFiltersByTagList from "../hooks/useTrackFiltersByResponse";
import AssistiveFiltersSection from "../assistiveFilters";
import useAssistiveFilters from "../hooks/useAssistiveFilters";
import { useRouteSlug } from "@/hooks";
import {
  AssistiveFilterOption,
  Facet,
  FilterOption,
  ProductListingFiltersMobProps,
  SelectedFiltersType
} from "../types";
import "./style.scss";

export const selectedFiltersContext = createContext<{
  selectedFilters: {
    [key: string]: Facet & { selectedOptions: FilterOption[] };
  };
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Facet & {
        selectedOptions: FilterOption[];
      };
    }>
  >;
  updateSelectedFilters: (selected: any) => {
    [key: string]: Facet & {
      selectedOptions: FilterOption[];
    };
  };
  onSelection: (...args) => void;
}>(null);

const ProductListingFiltersMob: FC<ProductListingFiltersMobProps> = ({
  filters,
  itemsTotal,
  appliedFilterCount,
  selectedFiltersData,
  listingPageData
}) => {
  const history = useHistory();
  const location = useLocation();
  const routeSlug = useRouteSlug();
  const assistiveFilter = useAssistiveFilters(listingPageData);
  if (!filters) return null;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    {}
  );
  const { enableBodyScroll, disableBodyScroll } = useBodyScrollability();

  useEffect(() => {
    if (!isFiltersOpen) {
      enableBodyScroll();
    }
  }, [isFiltersOpen]);

  const getUpdatedQuery = useCallback(
    (updatedSelection: {
      [key: string]: Facet & { selectedOptions: FilterOption[] };
    }) => {
      const { q: searchQuery } = qs.parse(location.search);
      const queryObject = Object.values(updatedSelection).reduce(
        (acc, filter) => {
          acc[filter.queryParam] = filter.selectedOptions.map(
            option => option.key
          );
          return acc;
        },
        {}
      );
      const query = qs.stringifyUrl(
        {
          url: location.pathname,
          query: { ...queryObject, q: searchQuery }
        },
        {
          skipNull: true,
          skipEmptyString: true,
          encode: false
        }
      );

      return query;
    },
    [selectedFilters]
  );

  const updateSelectedFilters = useCallback(
    selected => {
      let updated: {
        [key: string]: Facet & { selectedOptions: FilterOption[] };
      } = { ...selectedFilters };
      if (selected) {
        if (Array.isArray(selected)) {
          selected.forEach(item => {
            updated[item.queryParam] = item;
          });
        } else {
          updated[selected.queryParam] = selected;
        }
      }

      setSelectedFilters(selected => ({ ...selected, ...updated }));

      return updated;
    },
    [selectedFilters]
  );

  const onAssistiveFilterClick = (filter: AssistiveFilterOption) => {
    setSelectedFilters({});
    history.push(`/${routeSlug}${filter.url}`);
  };

  const onSelection = async selected => {
    const updatedSelection = updateSelectedFilters(selected);
    const query = getUpdatedQuery(updatedSelection);

    if (selected.queryParam === FILTER_QUERY_PARAMS.SORT) {
      PlpAnalytics.sort({
        sort_by: selected?.selectedOptions?.[0]?.defaultValue?.toLowerCase(),
        sort_location: SORT_LOCATION.PRODUCT_LIST
      });
    } else {
      handleFilterEvent(selected, updatedSelection);
    }

    history.push(query);
  };

  const onFiltersOpen = () => {
    setIsFiltersOpen(true);
    disableBodyScroll();
  };

  const onFiltersClose = () => setIsFiltersOpen(false);

  const flatSelectionFromResponse = useMemo(
    () =>
      selectedFiltersData?.flatMap(filterData => {
        if (filterData.type === FILTER_TYPES.PRICE) return [];
        const { data: filterOptions, ...filterOnlyData } = filterData;
        return filterOptions.map(option => ({
          filter: { queryParam: option.queryParam, ...filterOnlyData },
          ...option
        }));
      }),
    [selectedFiltersData]
  );

  useTrackFiltersByTagList(
    flatSelectionFromResponse,
    selectedFilters,
    setSelectedFilters,
    updateSelectedFilters
  );

  return (
    <>
      <div className="filters_wrapper_resp">
        <selectedFiltersContext.Provider
          value={{
            selectedFilters,
            setSelectedFilters,
            updateSelectedFilters,
            onSelection
          }}
        >
          <FiltersPanel
            itemsTotal={itemsTotal}
            filters={filters}
            onFiltersOpen={onFiltersOpen}
            appliedFilterCount={appliedFilterCount}
          />
          <FilterSidebar
            filters={filters}
            isFiltersOpen={isFiltersOpen}
            onFiltersClose={onFiltersClose}
            itemsTotal={itemsTotal}
          />
        </selectedFiltersContext.Provider>
      </div>
      {assistiveFilter && (
        <AssistiveFiltersSection
          staticFilter={assistiveFilter.prev_selection}
          filters={assistiveFilter.list}
          onFilterClick={onAssistiveFilterClick}
        />
      )}
    </>
  );
};

export default ProductListingFiltersMob;
