import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import DynamicBannersWrapper from "../../../components/dynamic/dynamicBanner";
import { PageSectionsProps } from "./types";

const PageSections: FC<PageSectionsProps> = ({ sections }) => {
  if (isEmpty(sections)) return null;
  return (
    <>
      {sections.map((section, key) => (
        <DynamicBannersWrapper
          section={section}
          key={key}
          bannerPageTitle={section.sectionTitle}
        />
      ))}
    </>
  );
};
export default PageSections;
