export const VISITOR_ID = "visitorId";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CLEAR_BANNERS_SECTIONS = "CLEAR_BANNERS_SECTIONS";
export const SAVE_BREADCRUMB_DATA = "SAVE_BREADCRUMB_DATA";
export const REDIRECTION_DATA = "REDIRECTION_DATA";
export const COMMON_DATA = "COMMON_DATA";
export const SET_GENERAL_INFO = "SET_GENERAL_INFO";
export const SET_BRANDS_DATA = "SET_BRANDS_DATA";
export const RECENTLY_VIEWED = "RECENTLY_VIEWED";
export const RECENTLY_VIEWED_PRODUCTS = "RECENTLY_VIEWED_PRODUCTS";
export const CLEAR_REDIRECTION_DATA = "CLEAR_REDIRECTION_DATA";
export const SUBSCRIBE_NOW = "SUBSCRIBE_NOW";
export const SET_STATES_DATA = "SET_STATES_DATA";
export const SET_LOYALITY_POINTS = "SET_LOYALITY_POINTS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const RESET_BREADCRUMB_DATA = "RESET_BREADCRUMB_DATA";
export const RESET_BRANDS_DATA = "RESET_BRANDS_DATA";
export const SHOW_ERROR_PAGE = "SHOW_ERROR_PAGE";
export const HIDE_ERROR_PAGE = "HIDE_ERROR_PAGE";
export const USER_ORIGIN_COUNTRY = "USER_ORIGIN_COUNTRY";
export const CHANGE_IS_HOME_PAGE_REDUX = "CHANGE_IS_HOME_PAGE_REDUX";
export const HIDE_PAGE_LOADER = "HIDE_PAGE_LOADER";
export const SHOW_PAGE_LOADER = "SHOW_PAGE_LOADER";
export const SET_PAYPAL_TOKEN = "SET_PAYPAL_TOKEN";
export const SET_SIZE_TYPE = "SET_SIZE_TYPE";
export const REQUIRED_EN = "Field is required.";
export const INVALID_CARD_EN = "Invalid Card!!";
export const MINIMUM_TWO_CHARS_EN =
  "Field should contain minimum 2 characters.";
export const NUMBER_EN = "Field should be a valid phone number.";
export const EMAIL_EN = "Field should be an email.";

export const BROWSER_STORAGE = {
  ACCESS_TOKEN: "access_token",
  GUEST_ACCESS_TOKEN: "guest_access_token",
  USER_COUNTRY: "user_country",
  USER_COUNTRY_SELECTION: "user_country_selection",
  PREV_MODE: "prev_mode",
  COOKIE_EXPIRY_TIME: 60 * 60 * 23,
  USER_EMAIL: "user_email",
  GUEST_CHECKOUT_TOKEN: "guest_checkout_token"
};

export const SET_REGISTRATION_DATA_STATUS = "SET_REGISTRATION_DATA_STATUS";
export const RESET_REGISTRATION_DATA_STATUS = "RESET_REGISTRATION_DATA_STATUS";
export const SET_SIGNUP_LOGIN_TOKEN = "SET_SIGNUP_LOGIN_TOKEN";
export const RESET_SIGNUP_LOGIN_TOKEN = "RESET_SIGNUP_LOGIN_TOKEN";
export const SET_SOCIAL_LOGIN_FLOW = "SET_SOCIAL_LOGIN_FLOW";
export const RESET_SOCIAL_LOGIN_FLOW = "RESET_SOCIAL_LOGIN_FLOW";
export const SET_SOCIAL_PHONE_CHECK_TOKEN = "SET_SOCIAL_PHONE_CHECK_TOKEN";
export const RESET_SOCIAL_PHONE_CHECK_TOKEN = "RESET_SOCIAL_PHONE_CHECK_TOKEN";
export const SET_LAST_REGISTRATION_STATUS_CODE =
  "SET_LAST_REGISTRATION_STATUS_CODE";
export const SET_REGISTRATION_ERROR = "SET_REGISTRATION_ERROR";
export const SET_ADDRESS_OTP_ERROR = "SET_ADDRESS_OTP_ERROR";
export const SET_ADDRESS_OTP_FLOW = "SET_ADDRESS_OTP_FLOW";

export const SET_COUNTRIES_MOBILE_DETAILS = "SET_COUNTRIES_MOBILE_DETAILS";
export const SET_SELECTED_COUNTRY_MOBILE_DETAILS =
  "SET_SELECTED_COUNTRY_MOBILE_DETAILS";

export const SET_ANALYTICS_DATA = "SET_ANALYTICS_DATA";
export const COUNTRY_CODE = "COUNTRY_CODE";
export const COUPON_CODE = "COUPON_CODE";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const SET_CONFIG_SETTINGS = "SET_CONFIG_SETTINGS";
export const RESET_CONFIG_SETTINGS = "RESET_CONFIG_SETTINGS";
export const SET_OTP_LOGIN_FLOW_BFL = "SET_OTP_LOGIN_FLOW_BFL";

export const UNIQUE_ID = "unique_id";
export const VIEW_GRID = "view_grid";
export const SET_IS_SERVER_LOADED_REDUX_STATE =
  "SET_IS_SERVER_LOADED_REDUX_STATE";
export const SET_IS_HEADER_FIXED = "SET_IS_HEADER_FIXED";
export const SET_COUNTRY_ID = "SET_COUNTRY_ID";
export const PREFERRED_LANGUAGE = "PREFERRED_LANGUAGE";
