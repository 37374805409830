import get from "lodash/get";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { postDashboardWebApi } from "../../webapis/apiResource";
import { SET_FOOTER_DATA } from "../constants";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";

import AnalyticService from "../../services/analytic-service";
import CatalogService from "../../services/catalogService";
import {
  SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  RESET_NEWS_LETTER_SUBSCRIPTION_DATA
} from "../constants";
import CommonAnalyticsEvent from "../../services/analytics/main/common";

export const setFooterData = content => ({
  type: SET_FOOTER_DATA,
  content
});

export const setNewsletterSubscriptionData = newsletter => ({
  type: SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  newsletter
});

export const resetNewsletterSubscriptionData = () => ({
  type: RESET_NEWS_LETTER_SUBSCRIPTION_DATA
});

export const fetchFooterData = createAsyncThunk(
  "footer/fetchData",
  async (_, thunkAPI) => {
    try {
      const response = await CatalogService.getFooterContent();

      thunkAPI.dispatch(setFooterData(response.result.content));
      return response.result.content;
    } catch (error) {
      handleFetchError(error, thunkAPI.dispatch);
    }
  }
);

export const thankYouSubscribingModal = payload => {
  const apiURL = `${BEConfig.subscribeNowApi.protocol}${BEConfig.subscribeNowApi.baseURL}/${BEConfig.subscribeNowApi.subscribeNowHandle}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(null, apiURL, payload, null, null, true)
      .then(response => {
        dispatch(hideLoadingIndicator());
        return true;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return false;
      });
  };
};

export const fetchSubscribeEmail = bodyData => async (dispatch, getState) => {
  const {
    customerApi: { baseURL, protocol, port, versionInfo, subscribeEmailHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${subscribeEmailHandle}`;
  dispatch(showLoadingIndicator());
  try {
    const response = await postDashboardWebApi(
      null,
      apiURL,
      bodyData,
      null,
      null,
      false
    );
    if (response.status === 200 && response.data) {
      const customerId = get(response, "data.customerId", "");
      const userType = get(response, "data.userType", "");
      const email = get(bodyData, "email", "");
      const newsletterSubscription = {
        email,
        customerId,
        userType
      };
      dispatch(setNewsletterSubscriptionData(newsletterSubscription));
      CommonAnalyticsEvent.subscribeNewsletter(newsletterSubscription);
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(hideLoadingIndicator());
  }
};
