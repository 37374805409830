import { Dispatch, useCallback, useEffect, useState } from "react";
import isUndefined from "lodash/isUndefined";
import CartService, {
  CartProduct,
  OrderSummary,
  OrderSummaryDeliveryDetails
} from "../../../services/cartService";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { selectLoggedInStatus } from "../../../util/selectors";
import { useCurrency, useLanguage } from "../../../hooks";
import { fetchLoyaltyDetails } from "../../../redux/actions/myAccount.action";
import { DELIVERY_TYPES } from "@/constants";

export const useGetOrderSummary = (
  cart: CartProduct[],
  selectedDelivery: OrderSummaryDeliveryDetails | null,
  setSelectedDelivery: Dispatch<OrderSummaryDeliveryDetails | null>
) => {
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const userLoggedIn = useAppSelector(selectLoggedInStatus);
  const currencyCode = useCurrency();
  const countryId = useAppSelector(state => state.common.settings.countryId);
  const [orderSummary, setOrderSummary] = useState<OrderSummary | null>(null);
  const [tierToken, setTierToken] = useState<undefined | null | string>(
    undefined
  );

  useEffect(() => {
    (async () => {
      let tierToken = null;
      if (userLoggedIn) {
        const loyaltyData = await dispatch(fetchLoyaltyDetails(false));
        tierToken = loyaltyData?.tierToken ?? null;
      }
      setTierToken(tierToken);
    })();
  }, [language, currencyCode, countryId, userLoggedIn]);

  // Initial fetch + refetch after cart changes
  useEffect(() => {
    if (!isUndefined(tierToken)) {
      (async () => {
        setOrderSummary(null);
        setSelectedDelivery(null);
        const deliveryTypeKey = selectedDelivery?.deliveryType?.key;
        const response = await CartService.getCartSummary({
          //DELIVERY_TYPES.DELIVERY_ADDRESS should be replaced by "" but only after the BE is updated to handle it
          deliveryType: deliveryTypeKey ?? DELIVERY_TYPES.DELIVERY_ADDRESS
        });

        setOrderSummary(response);

        const deliveryDetails = getSelectedDelivery(response, deliveryTypeKey);
        setSelectedDelivery(deliveryDetails);
      })();
    }
  }, [cart, tierToken]);

  const refetchOrderSummary = useCallback(
    async (deliveryTypeKey: string) => {
      setOrderSummary(null);
      setSelectedDelivery(null);
      const response = await CartService.getCartSummary({
        deliveryType: deliveryTypeKey
      });

      setOrderSummary(response);

      const deliveryDetails = getSelectedDelivery(response, deliveryTypeKey);
      setSelectedDelivery(deliveryDetails);
    },
    [cart, tierToken]
  );

  return { orderSummary, refetchOrderSummary };
};

const getSelectedDelivery = (
  response: OrderSummary,
  prevSelectedDeliveryType?: string
) =>
  response.deliveryDetails.find(
    item => item.deliveryType.key === prevSelectedDeliveryType
  ) ?? response.deliveryDetails[0];
