import { pushGaProductClick, createObjForGASyte } from "./utils";
import get from "lodash/get";

class SyteAnalyticService {
  trackSyteSearchHeader = () => {
    const syteSearchObj = createObjForGASyte.syteSearchHeader();
    pushGaProductClick.pushGaSyteSearch(syteSearchObj);
  };

  trackSyteSearch = trackData => {
    const item = get(trackData, "item", {});
    const pageTitle = get(trackData, "pageTitle", "");
    const syteSearchObj = createObjForGASyte.syteSearchListPage(
      item,
      pageTitle
    );
    pushGaProductClick.pushGaSyteSearch(syteSearchObj);
  };

  trackSyteSearchProductPage = item => {
    const syteSearchObj = createObjForGASyte.syteSearchProductPage(item);
    pushGaProductClick.pushGaSyteSearch(syteSearchObj);
  };

  trackSyteProductClick = data => {
    const syteSearchObj = createObjForGASyte.syteProductClick(data);
    pushGaProductClick.syteProductClick(syteSearchObj);
  };
  trackViewSimilarClick = data => {
    pushGaProductClick.pushGa({
      event: "viewSimilar",
      ...data
    });
  };
}

export default SyteAnalyticService;
