import { Product } from "@/services/productService";
import { FILTER_TYPES } from "./constants";

export type FilterOption = TextAttributeField & {
  count?: number;
  isSelected: boolean;
  logoUrl?: string;
  icon?: Icon;
  queryParam?: string;
};

export enum IconType {
  COLOR = "color",
  IMAGE = "image"
}

export type Icon = {
  type: IconType;
  value: string;
};

export type PriceFilterOption = TextAttributeField & {
  range: {
    min: number;
    max: number;
  };
  selectedRange: {
    min: number;
    max: number;
  };
  currency: string;
  isSelected: boolean;
  label: string;
};

export type CategoryFilter = Omit<
  GeneralFacet & { data: CategoryFilterOption[] },
  "type" | "sortOrder"
>;

export type CategoryFilterOption = FilterOption & {
  queryParam: string;
  isSelected: boolean;
  extraLabel: string;
  label: string;
  key: string;
  children?: CategoryFilterOption[];
};

export type SingleSize = Required<Omit<FilterOption, "icon">> & {
  sortOrder: number;
};

export type SizeFilterOption = FilterOption & {
  sortOrder: number;
  children: SizeFilterOption[] | SingleSize[];
};

export type GeneralFacet = {
  queryParam: string;
  label: string;
  defaultLabel?: string;
  allowedUrls: string[];
  sortOrder: number;
  search?: { enabled: boolean; placeholder: string };
};

export type SingleSelectFacet = {
  type: FILTER_TYPES.SINGLE_SELECT;
  data: FilterOption[];
};

export type MultiSelectFacet = {
  type: FILTER_TYPES.MULTI_SELECT;
  data: FilterOption[];
};

export type PriceFacet = {
  type: FILTER_TYPES.PRICE;
  data: PriceFilterOption;
};

export type SizeFacet = {
  type: FILTER_TYPES.SIZE;
  data: SizeFilterOption[];
};

export type CategoryFacet = {
  type: FILTER_TYPES.CATEGORY;
  data: CategoryFilterOption[];
};

export type Facet = GeneralFacet &
  (
    | SingleSelectFacet
    | MultiSelectFacet
    | PriceFacet
    | SizeFacet
    | CategoryFacet
  );

export type AssistiveFilterOption = {
  url: string;
  label: string;
  defaultValue: string;
};

export type AssistiveFilter = {
  level: string;
  list: AssistiveFilterOption[];
  prev_selection: AssistiveFilterOption;
};

export type ProductsListingPageData = {
  en_title: string;
  hits: Product[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
  facets: Facet[];
  assistiveFilter: AssistiveFilter;
  headingH1: string;
  appliedFilterCount: number;
  sales: any;
  selectedFilters: Facet[];
  url?: string;
  queryID?: string;
  index: string;
};

export type MetaData = {
  heading1: { label: string; defaultValue: string };
  heading2: { label: string; defaultValue: string };
  metaDescription: string;
  metaKeyword: string;
  title: string;
};

export type FilterType = {
  queryParam: string;
  data: FilterOption[];
  label: string;
};

export type FilterRangePropsType = {
  filter: Omit<GeneralFacet & { data: PriceFilterOption }, "sortOrder">;
  setToggledFilter: (isToggle: any) => void;
  toggledFilter: FilterType;
};

export type SelectedFiltersType = {
  [key: string]: Facet & { selectedOptions: FilterOption[] };
};

export type ProductListingFiltersMobProps = {
  filters: Facet[];
  itemsTotal: number;
  appliedFilterCount: number;
  selectedFiltersData: Facet[];
  listingPageData: ProductsListingPageData;
};

export type FilterRangeProps = {
  filter: Omit<GeneralFacet & { data: PriceFilterOption }, "sortOrder">;
  onSelection: (...args) => void;
};

export type Impressions =
  | EmptyObject
  | Record<number, Product & { position: number }>;
