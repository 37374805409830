import React, { useContext, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import DynamicBannersWrapper from "../../../components/dynamic/dynamicBanner";
import { Content } from "@/routes/catalogPage/types";
import { emptyResultsSectionsContext } from "../productListingPage";

const EmptyResultsSection = () => {
  const sections = useContext<Content[]>(emptyResultsSectionsContext);

  if (isEmpty(sections)) return null;

  return (
    <>
      {sections.map((section, key) => (
        <DynamicBannersWrapper
          section={section}
          key={key}
          bannerPageTitle={section.sectionTitle}
        />
      ))}
    </>
  );
};
export default EmptyResultsSection;
