import { ENV_CONFIG } from "../../config/env";

const FACEBOOK_APP_ID = "XXXXXXXXX";

export const getMetaTags = ({
  title,
  description,
  image,
  isIndexable,
  siteName,
  pathname
}: {
  title: string;
  description: string;
  image: string;
  isIndexable: boolean;
  siteName: string;
  pathname: string;
}) => {
  const metaTags = [
    {
      itemprop: "name",
      content: title
    },
    {
      itemprop: "description",
      content: description
    },
    {
      itemprop: "image",
      content: image
    },
    {
      name: "description",
      content: description
    },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "" },
    {
      name: "twitter:title",
      content: title
    },
    {
      name: "twitter:description",
      content: description
    },
    {
      name: "twitter:image:src",
      content: image
    },
    {
      property: "og:title",
      content: title
    },
    { property: "og:type", content: "website" },
    { property: "og:url", content: ENV_CONFIG.baseURL + pathname },
    {
      property: "og:image",
      content: image
    },
    { property: "og:image:width", content: "600" },
    { property: "og:image:height", content: "600" },
    {
      property: "og:description",
      content: description
    },
    { property: "og:site_name", content: siteName },
    { property: "fb:app_id", content: FACEBOOK_APP_ID }
  ];

  if (isIndexable) {
    metaTags.push({ name: "robots", content: "index,follow" });
  } else {
    metaTags.push({ name: "robots", content: "noindex,follow" });
  }

  return metaTags;
};

export const getSEOStructuredLogo = brand => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  url: ENV_CONFIG.baseURL,
  logo: brand.storeLogoImageLink || brand.iconImageLink
});
