import React, { FC, useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import cn from "clsx";
import {
  RootFilterContent,
  selectedCategoryFilterContext
} from "./filterCategoryMob";
import FilterCategoryLeaf from "./filterCategoryLeaf";
import { CategoryFilterOption } from "../types";

const FilterCategoryDropDownList: FC<{
  filterContent: CategoryFilterOption;
}> = ({ filterContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(false);

  const {
    selectedOptions,
    onSelection,
    selectedDropDown,
    setSelectedDropDown
  } = useContext(selectedCategoryFilterContext);

  const saveSelectedDropDown = (action = true) => {
    if (action) {
      setSelectedDropDown(current => {
        return {
          ...current,
          [filterContent.queryParam]: filterContent.key
        };
      });
    } else {
      setSelectedDropDown(current => {
        const copy = { ...current };
        delete copy[filterContent.queryParam];
        return copy;
      });
    }
  };

  useEffect(() => {
    if (selectedDropDown[filterContent.queryParam] !== filterContent.key)
      setIsOpen(false);
  }, [selectedDropDown]);

  useEffect(() => {
    if (filterContent.isSelected) {
      saveSelectedDropDown();
      setIsOpen(filterContent.isSelected);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selectedOptions) && filterContent.isSelected) {
      saveSelectedDropDown();
      setIsOpen(filterContent.isSelected);
    }
    setSelected(filterContent.key === selectedOptions[0]?.key);
    const findSameOptionKey = (array, key) => {
      return array.some(item => {
        if (item.key === key) {
          return true;
        } else if (item.children) {
          return findSameOptionKey(item.children, key);
        } else {
          return false;
        }
      });
    };

    setSelectedLabel(
      !isEmpty(selectedOptions)
        ? selectedOptions.some(item =>
            findSameOptionKey(
              [filterContent, ...filterContent.children],
              item.key
            )
          )
        : filterContent.isSelected &&
            filterContent.children.every(item => item.isSelected)
    );
  }, [filterContent, selectedOptions]);

  const toggleOpen = () => {
    setIsOpen(isOpen => !isOpen);
    saveSelectedDropDown(!isOpen);
  };

  return filterContent.label ? (
    <li>
      <h5
        className={cn("cursor_pointer category_label", {
          bold: selectedLabel
        })}
        onClick={toggleOpen}
      >
        <span className="square_toggle">{isOpen ? "–" : "+"}</span>
        {filterContent.label}
      </h5>
      {isOpen && (
        <ul className="hierarchical_categories2_item">
          {filterContent?.extraLabel && (
            <FilterCategoryLeaf
              option={{
                ...filterContent,
                label: filterContent.extraLabel,
                //@ts-ignore
                selectedOptions: [filterContent]
              }}
            />
          )}
          {filterContent?.children &&
            filterContent.children.map(option => (
              <RootFilterContent key={option.key} filterContent={option} />
            ))}
        </ul>
      )}
    </li>
  ) : null;
};

export default FilterCategoryDropDownList;
