import React from "react";
import DynamicBannersWrapper from "../../../components/dynamic/dynamicBanner";
import { useAppSelector } from "../../../redux/store";

const SectionsWrapper = ({ sectionSlug }) => {
  const { cms } = useAppSelector(state => state.dynamicPage.data);

  if (!cms?.length) return null;

  return (
    <>
      {cms.map((section, index) => {
        if (section.slug === sectionSlug) {
          return (
            <div className="cms_html_data" key={index}>
              <DynamicBannersWrapper
                section={section}
                bannerPageTitle={section.sectionTitle}
              />
            </div>
          );
        }
      })}
    </>
  );
};

export default SectionsWrapper;
