import React, { useEffect, useMemo, useState, FC, useContext } from "react";
import cn from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import { ErrorBoundary } from "react-error-boundary";
import isEmpty from "lodash/isEmpty";
import { useIsRTL } from "@/hooks";
import useSelectedByQuery from "../hooks/useSelectedByQuery";
import { FILTER_QUERY_PARAMS } from "../constants";
import { selectedFiltersContext } from ".";
import FilterWrapperElement from "./filterWrapperElement";
import FilterContainer from "./filtersContainer";
import { ApplyButton } from "./applyButton";
import { Facet } from "../types";
import "./style.scss";

const TABLET_WIDTH = 812;

const FilterSidebar: FC<{
  filters: Facet[];
  isFiltersOpen: boolean;
  onFiltersClose: () => void;
  itemsTotal: number;
}> = ({ filters, isFiltersOpen, onFiltersClose, itemsTotal }) => {
  const [toggledFilter, setToggledFilter] = useState(null);
  const [screenHeight, setScreenHeight] = useState(0);
  const isRTL = useIsRTL();
  const selectedByQuery = useSelectedByQuery();
  const { t } = useTranslation("productListing");
  const { onSelection, setSelectedFilters, selectedFilters } = useContext(
    selectedFiltersContext
  );
  const isSizeFilter =
    toggledFilter && toggledFilter.queryParam === FILTER_QUERY_PARAMS.SIZE;

  const sidebarWidth = isSizeFilter ? "100%" : "85%";

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    const handleResize = () => {
      if (window.innerWidth > TABLET_WIDTH) {
        onFiltersClose();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeFilter = () => {
    setToggledFilter({});
    onFiltersClose();
  };

  const filtersData = useMemo(
    () =>
      [...filters]
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((filter, index) => {
          const { sortOrder: alreadyUsed, ...neededData } = filter;
          return (
            <ErrorBoundary key={`${index}${filter.queryParam}`} fallback={null}>
              <FilterContainer
                filter={neededData}
                setToggledFilter={setToggledFilter}
                toggledFilter={toggledFilter}
                onApplyButtonClick={onSelection}
              />
            </ErrorBoundary>
          );
        }),
    [filters, toggledFilter, selectedFilters, onSelection]
  );

  const clearAllSelectedFilters = () => {
    if (!isEmpty(selectedFilters) || !isEmpty(selectedByQuery)) {
      const clearedFilters = Object.keys(selectedFilters).map(item => {
        return { queryParam: item, selectedOptions: [] };
      });
      onSelection(clearedFilters);
      setSelectedFilters({});
    }
  };

  const showItemsButtonText = (
    <Trans i18nKey="showItemsButtonText" ns="productListing">
      {/* @ts-ignore */}
      Show <span>{{ itemsTotal }}</span> Results
    </Trans>
  );

  return (
    <div className="sidebar_container">
      <div
        className={cn("sidebar", {
          open: isFiltersOpen
        })}
        style={{ width: sidebarWidth }}
      >
        <div className="sidebar_content">
          <FilterWrapperElement
            iconClassName="close"
            onIconClick={closeFilter}
            filterTitle={t("filterTitle")}
            onClearBtnClick={clearAllSelectedFilters}
            isHeaderVisible={isEmpty(toggledFilter)}
          >
            <div className={cn("filter_wrapper", { arabic: isRTL })}>
              <div className="filter_types">
                {!isEmpty(toggledFilter) ? (
                  filtersData
                ) : (
                  <Scrollbars
                    autoHeight
                    autoHeightMin={55}
                    autoHeightMax={`calc(${screenHeight}px - 100px)`}
                  >
                    {filtersData}
                  </Scrollbars>
                )}
              </div>
            </div>
            {isEmpty(toggledFilter) && (
              <ApplyButton
                onButtonClick={closeFilter}
                buttonText={showItemsButtonText}
              />
            )}
          </FilterWrapperElement>
        </div>
      </div>
      <div className="sidebar_shadow" onClick={closeFilter} />
    </div>
  );
};

export default FilterSidebar;
