import i18next from "i18next";
import Backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";

import { isServer } from "./util";
import { ContextStorage } from "./libs/contextStorage";
import { PREFERRED_LANGUAGE } from "./redux/constants";
import { DEFAULT_LANGUAGE } from "./constants";

const getLanguageFromUrl = (pathname: string) => {
  return pathname
    ?.split("/")
    ?.filter(e => e)?.[0]
    ?.split("-")?.[0];
};

export let initialLanguage: Language;
export let initialI18nStore: Record<string, any>;
if (!isServer) {
  initialLanguage = window.__i18n__?.language;
  initialI18nStore = window.__i18n__?.store;
  delete window.__i18n__;
}

export const initI18next = () => {
  if (isServer) return;

  i18next
    .use(Backend)
    .use({
      type: "languageDetector",
      detect: () =>
        getLanguageFromUrl(window.location.pathname) ||
        ContextStorage.cookies.get(PREFERRED_LANGUAGE) ||
        DEFAULT_LANGUAGE
    })
    .use(initReactI18next)
    /*
     * Init i18next
     * for all options read:
     * https://www.i18next.com/overview/configuration-options
     */
    .init({
      fallbackLng: "en",
      debug: false,
      ns: ["seo"],
      defaultNS: "seo",
      lowerCaseLng: true,
      load: "languageOnly",
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json"
      },
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false
      }
    });
};

export default i18next;
