import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { AnalyticService } from "../../../services";
import useOnScrollHandling from "../../../hooks/intersection-observer";
import {
  selectAuthReducer,
  selectDeviceType,
  selectPageAnalitic
} from "../../../util/selectors";
import { isServer } from "../../../redux/store";
import { getDeviceType } from "../../../util";
import CommonAnalyticsEvent from "../../../services/analytics/main/common";
import { useCountryShort, useLongLanguage } from "@/hooks";

const BannerIntersectionObserver = ({ data, children }) => {
  const bannerRef = useRef(null);

  const { isIntersecting } = useOnScrollHandling(bannerRef, {
    threshold: 0.75,
    constantTracking: true
  });
  const { imageListData = {}, ...analyticsData } = data;

  const countrySlug = useCountryShort();
  const deviceTypeSSR = useSelector(state => selectDeviceType(state));
  const deviceType = deviceTypeSSR || getDeviceType();
  const language = useLongLanguage();
  const { customerId } = useSelector(selectAuthReducer);
  const analyticData = useSelector(selectPageAnalitic);
  const dataForAnalytic = {
    ...analyticsData,
    country: countrySlug.toUpperCase(),
    pageUrl: !isServer && window.location.href,
    device: deviceType?.toUpperCase(),
    language
  };

  useEffect(() => {
    if (isIntersecting) {
      if (!isEmpty(imageListData)) {
        if (imageListData.attributes) {
          const payload = Array.isArray(imageListData?.attributes)
            ? imageListData?.attributes
            : [];
          if (Boolean(payload.length)) {
            CommonAnalyticsEvent.viewPromotion({
              attributes: payload
            });
          }
        }
      }
      if (analyticData?.analytics?.variant) {
        AnalyticService.banner.pushStormlyBannerImpressions(
          dataForAnalytic,
          analyticData.analytics?.variant,
          customerId
        );
      }
    }
  }, [isIntersecting]);

  const handleBannerClick = () => {
    if (!isEmpty(imageListData)) {
      if (imageListData.bannerId) {
        CommonAnalyticsEvent.selectBanner({
          bannerId: imageListData.bannerId,
          bannerName: imageListData.bannerTitle || ""
        });
      }
      if (imageListData.attributes) {
        const payload = Array.isArray(imageListData?.attributes)
          ? imageListData?.attributes
          : [];
        if (Boolean(payload.length)) {
          CommonAnalyticsEvent.selectPromotion({
            attributes: payload
          });
        }
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%"
      }}
      ref={bannerRef}
      onClick={handleBannerClick}
    >
      {children}
    </div>
  );
};

export default BannerIntersectionObserver;
