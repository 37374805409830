import React, { FC, useContext, useEffect, useRef, useState } from "react";
import cn from "clsx";
import { selectedCategoryFilterContext } from "./filterCategoryMob";
import { FilterOption } from "../types";

const FilterCategoryLeaf: FC<{
  option: FilterOption;
}> = ({ option }) => {
  const [selected, setSelected] = useState(option?.isSelected);
  const { selectedOptions, onSelection } = useContext(
    selectedCategoryFilterContext
  );

  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (option.isSelected) {
      ref.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const isSelectedOption = selectedOptions?.some(
      item => item.key === option?.key
    );
    setSelected(isSelectedOption);
  }, [option, selectedOptions]);

  return option?.label ? (
    <li ref={ref}>
      <label className="filter_selected_content_wrapper">
        <div className={cn("filter_selected_content", { bold: selected })}>
          {`${option.label}${option.count ? ` - ${option.count}` : ""}`}
        </div>
        <div
          className={cn("checkbox-wrapper", {
            checked: selected
          })}
        >
          <input
            type="checkbox"
            checked={selected}
            readOnly
            onClick={e => onSelection({ option, isLeaf: true }, e)}
          />
          <span className="checkbox-circle" />
        </div>
      </label>
    </li>
  ) : null;
};

export default FilterCategoryLeaf;
