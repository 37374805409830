import get from "lodash/get";
import { pushGaProductClick, createObjForGA } from "./utils";

class OrderAnalyticService {
  trackMulipleOrderCancellation = trackData => {
    const orderItems = get(
      trackData,
      "orderDetails.cancelOrder.orderItems",
      []
    );
    const cancelOrder = get(trackData, "orderDetails.cancelOrder", {});
    const { ecommerce, outerObj, actionField } =
      createObjForGA.multipleNetCoreItems(orderItems, cancelOrder);
    pushGaProductClick.pushGaCancelOrder(ecommerce, outerObj, actionField);
  };

  trackSingleOrderCancellation = trackData => {
    const orderItem = get(trackData, "orderItem", {});
    const cancelOrder = get(trackData, "orderDetails.cancelOrder", {});
    const { ecommerce, outerObj, actionField } =
      createObjForGA.outerAndEcommerce({ item: orderItem, order: cancelOrder });

    pushGaProductClick.pushGaCancelOrder(ecommerce, outerObj, actionField);
  };
}

export default OrderAnalyticService;
