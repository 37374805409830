import GoogleAnalytics from "../../googleAnalytics";
import {
  Accordion,
  AccountOptions,
  ChangeCountry,
  ChangeLanguage,
  ClickToAction,
  EmptySearchResult,
  FooterClick,
  FormErrors,
  NavTopClick,
  RecommendedProductSearch,
  Search,
  SelectBanner,
  SelectPromotion,
  SizeChart,
  ViewAllFaq,
  ViewBanner,
  ViewPromotion,
  ViewSearchResult,
  ViewProduct,
  SocialClick,
  FormAbandonment,
  PaymentErrorProps
} from "./types";

class CommonAnalyticsService {
  // === Ecens events ===

  /* * Header Related Events  */
  viewMiniCart() {
    GoogleAnalytics.viewMiniCart();
  }
  accountOptions(payload: AccountOptions) {
    GoogleAnalytics.accountOptions(payload);
  }
  changeCountry(payload: ChangeCountry) {
    GoogleAnalytics.changeCountry(payload);
  }
  changeLanguage(payload: ChangeLanguage) {
    GoogleAnalytics.changeLanguage(payload);
  }
  navTopClick(payload: NavTopClick) {
    GoogleAnalytics.navTopClick(payload);
  }
  navClick(data) {
    GoogleAnalytics.navigationClick(data);
  }
  megaMenuClick(menuItems) {
    GoogleAnalytics.megaMenuClick(menuItems);
  }
  lvl1MenuClick(menuItem) {
    GoogleAnalytics.lvl1MenuClick(menuItem);
  }
  lvl2MenuClick(menuItem) {
    GoogleAnalytics.lvl2MenuClick(menuItem);
  }
  inputSearchInteraction() {
    GoogleAnalytics.inputSearchInteraction();
  }
  syteSearchInteraction() {
    GoogleAnalytics.syteSearchInteraction();
  }
  search(payload: Search) {
    GoogleAnalytics.search(payload);
  }
  emptySearchResult(payload: EmptySearchResult) {
    GoogleAnalytics.emptySearchResult(payload);
  }
  viewSearchResult(payload: ViewSearchResult) {
    GoogleAnalytics.viewSearchResult(payload);
  }
  recommendedProductSearch(payload: RecommendedProductSearch) {
    GoogleAnalytics.recommendedProductSearch(payload);
  }

  /** * All common form related events */
  formStart(formName: string) {
    GoogleAnalytics.formStart(formName);
  }
  formError(errors: FormErrors) {
    GoogleAnalytics.formError(errors);
  }
  formComplete(formName: string) {
    GoogleAnalytics.formComplete(formName);
  }

  formAbandonment(data: FormAbandonment) {
    GoogleAnalytics.formAbandonment(data);
  }

  /** * OTP related events */
  requestOTP() {
    GoogleAnalytics.requestOTP();
  }
  resendOTP() {
    GoogleAnalytics.resendOTP();
  }
  validatedOTP() {
    GoogleAnalytics.validatedOTP();
  }

  /** * Banner related events */
  selectBanner(payload: SelectBanner) {
    GoogleAnalytics.selectBanner(payload);
  }
  viewPromotion(payload: ViewPromotion) {
    GoogleAnalytics.viewPromotion(payload);
  }
  selectPromotion(payload: SelectPromotion) {
    GoogleAnalytics.selectPromotion(payload);
  }

  /** Footer and its sub events */
  sizeChart(payload: SizeChart) {
    GoogleAnalytics.sizeChart(payload);
  }
  nearByStore(payload) {
    GoogleAnalytics.nearByStore(payload);
  }
  subscribeNewsletter(payload) {
    GoogleAnalytics.subscribeNewsletter(payload);
  }
  footerClick(payload: FooterClick) {
    GoogleAnalytics.footerClick(payload);
  }
  viewAllFaq(payload: ViewAllFaq) {
    GoogleAnalytics.viewAllFaq(payload);
  }

  /** * Click To Action Events */
  clickToAction(payload: ClickToAction) {
    GoogleAnalytics.clickToAction(payload);
  }
  addAddressCTA() {
    GoogleAnalytics.addAddressCTA();
  }
  accordion(payload: Accordion) {
    GoogleAnalytics.accordion(payload);
  }

  socialClick(payload: SocialClick) {
    GoogleAnalytics.socialClick(payload);
  }

  // Meta Event
  viewPage(payload) {
    GoogleAnalytics.viewPage(payload);
  }

  /** * All product related events */
  viewProduct(payload: ViewProduct) {
    GoogleAnalytics.viewProduct(payload);
  }

  paymentError(data: PaymentErrorProps) {
    GoogleAnalytics.paymentError(data);
  }

  // === Other events ===

  trackSessionStart(customerId: string) {
    GoogleAnalytics.sessionStart(customerId);
  }
}

const CommonAnalyticsEvent = new CommonAnalyticsService();
export default CommonAnalyticsEvent;
