import get from "lodash/get";
import trim from "lodash/trim";
import { pushGaProductClick } from "./utils";
import { GA_EVENTS } from "./events";

const possibleFirstNameKeys = [
  "given_name",
  "givenName",
  "first_name",
  "firstName",
  "name"
];
const possibleLastNameKeys = [
  "family_name",
  "familyName",
  "last_name",
  "lastName",
  "surname"
];

const findExistedKey = (keys, object) =>
  keys.find(keyName => object?.[keyName]);

class AuthAnalyticService {
  trackSignIn = trackData => {
    const email = get(trackData, "userProfile.email", "");
    const first_name = get(trackData, "userProfile.firstname", "");
    const last_name = get(trackData, "userProfile.lastname", "");
    const customerId = get(trackData, "userProfile.customerId", "");
    pushGaProductClick.pushGa({
      event: "signIn",
      info: {
        email,
        loginFrom: "normal",
        first_name,
        last_name,
        customerId
      }
    });
  };

  trackSocialLogin = trackData => {
    const email = get(trackData, "response.email", "");
    const countryName = get(trackData, "settings.countryCode", "");

    const firstNameKey = findExistedKey(
      possibleFirstNameKeys,
      trackData.response
    );
    const lastNameKey = findExistedKey(
      possibleLastNameKeys,
      trackData.response
    );

    const firstName = get(trackData, ["response", firstNameKey], "");
    const lastName = get(trackData, ["response", lastNameKey], "");
    const customerId = get(trackData, "resp.data.customerId", "");
    const loginFrom = get(trackData, "loginFrom", "");
    pushGaProductClick.pushGa({
      event: "signIn",
      info: {
        email,
        countryName,
        loginFrom,
        firstName,
        lastName,
        customerId
      }
    });
  };

  trackSignUp = trackData => {
    const bodyData = get(trackData, "data", {});
    const nationality = get(trackData, "countryName", "");
    const customerId = get(trackData, "customerId", "");
    const country = get(trackData, "country", "");
    const email = get(bodyData, "email", "");

    pushGaProductClick.pushGa({
      event: "signUp",
      info: { ...bodyData, nationality, customerId },
      email,
      country
    });
  };

  trackProfileUpdate = trackData => {
    const phone = get(trackData, "updatedMyDetails.phone", "");
    const gender = get(trackData, "updatedMyDetails.gender", "");
    const firstName = trim(get(trackData, "updatedMyDetails.firstname", ""));
    const lastName = trim(get(trackData, "updatedMyDetails.lastname", ""));
    const customerId = trim(get(trackData, "customerId", ""));
    pushGaProductClick.pushGa({
      event: GA_EVENTS.profileUpdate,
      profileUpdate: {
        phone,
        gender,
        firstName,
        lastName,
        customerId
      }
    });
  };

  trackLogout = () => {
    pushGaProductClick.pushGa({
      event: "logout"
    });
  };
}

export default AuthAnalyticService;
