import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import { pushGaProductClick, createObjForGA } from "./utils";
import { GA_EVENTS } from "./events";
import {
  TrackCheckoutProps,
  TrackCheckoutStepOneProps,
  TrackCheckoutStepThreeProps,
  TrackCheckoutStepTwoProps
} from "./type";

class CheckoutAnalyticService {
  trackCheckout = (trackData: TrackCheckoutProps) => {
    const deliveryType = get(trackData, "deliveryType", "");
    const actionField = get(trackData, "actionField", {});
    const orderItems = get(trackData, "orderItems", []);
    const total_amount = get(trackData, "totalAmount", 0);
    const sub_total = get(trackData, "subTotal", 0);

    const { ecommerce, outerObj } =
      createObjForGA.multipleNetCoreItems(orderItems);

    const analyticData = {
      ...outerObj,
      deliveryType: lowerCase(deliveryType),
      total_amount,
      sub_total
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
  };

  trackCheckoutStepOne = (trackData: TrackCheckoutStepOneProps) => {
    const actionField = { step: 1, option: "login" };
    const orderItems = get(trackData, "orderItems", []);
    const total_amount = get(trackData, "totalAmount", []);
    const sub_total = get(trackData, "subTotal", []);
    const delivery_type = get(trackData, "deliveryType", 0);

    const { ecommerce, outerObj } =
      createObjForGA.multipleNetCoreItems(orderItems);

    const analyticData = {
      ...outerObj,
      Total: total_amount,
      delivery_type,
      total_amount,
      sub_total
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
    pushGaProductClick.pushNTShippingAddress({ ...analyticData });
  };

  trackCheckoutStepTwo = (trackData: TrackCheckoutStepTwoProps) => {
    const orderItems = get(trackData, "orderItems", []);
    const delivery_type = get(trackData, "deliveryType", "");
    const total_amount = get(trackData, "totalAmount", []);
    const sub_total = get(trackData, "subTotal", []);
    const total_items = get(trackData, "totalItems", []);
    const address = get(trackData, "address", "");
    const addressCountry = get(address, "country", "");
    const addressCity = get(address, "city", "");
    const addressArea = get(address, "area", "");
    const addressAddressLine = get(address, "addressLine1", "");

    const actionField = {
      step: 2,
      option: delivery_type.toLowerCase()
    };

    const { ecommerce, outerObj } =
      createObjForGA.multipleNetCoreItems(orderItems);

    const netCoreOuterObj = {
      ...outerObj,
      address: {
        country: addressCountry,
        city: addressCity,
        area: addressArea,
        address1: addressAddressLine,
        address2: ""
      }
    };
    const analyticData = {
      ...outerObj,
      Total: total_amount,
      delivery_type,
      total_amount,
      sub_total,
      total_items
    };
    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
    pushGaProductClick.pushNTShippingAddress(netCoreOuterObj);
  };

  trackCheckoutStepThree = (trackData: TrackCheckoutStepThreeProps) => {
    const orderItems = get(trackData, "orderItems", []);
    const selected_payment = get(trackData, "selectedPayment", "");
    const delivery_type = get(trackData, "deliveryType", "");
    const total_amount = get(trackData, "totalAmount", []);
    const sub_total = get(trackData, "subTotal", []);
    const total_items = get(trackData, "totalItems", []);

    const actionField = {
      step: 3,
      option: selected_payment
    };

    const { ecommerce, outerObj } = createObjForGA.multipleItems(orderItems);
    const analyticData = {
      ...outerObj,
      selected_payment,
      delivery_type,
      total_amount,
      sub_total,
      total_items
    };
    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
  };

  trackApplyVoucher = trackData => {
    const coupenCode = get(trackData, "voucherNumber", "");
    const discount = get(trackData, "discount", "");
    const coupenStatus = get(trackData, "coupenStatus", false);
    pushGaProductClick.pushGa({
      event: "couponRedemption",
      coupon_code: coupenCode,
      coupon_discount_value: discount,
      coupon_status: coupenStatus
    });
  };

  trackCheckoutStepFlow = ({ step, name }) => {
    pushGaProductClick.pushGa({
      event: "checkout_step_flow",
      step,
      name
    });
  };

  trackAddressGuest = trackData => {
    const guestCustomerId = trackData?.customerId;
    const firstName = trackData?.firstName;
    const lastName = trackData?.lastName;
    const emailConsent = trackData?.emailConsent;
    const email = trackData?.email;

    pushGaProductClick.pushGa({
      event: GA_EVENTS.guestAddress,
      guestDetails: {
        firstName,
        lastName,
        emailConsent,
        email,
        customerId: guestCustomerId
      }
    });
  };
}

export default CheckoutAnalyticService;
