import React, { FC, forwardRef } from "react";
import cn from "clsx";
import FilterOptionIcon from "./filterOptionIcon";
import { FilterOption } from "../types";

type FilterOptionItemProps = {
  option: FilterOption;
  isSelected: boolean;
  onSelection: (...args) => void;
  containerRef?: React.MutableRefObject<HTMLLIElement>;
};

const FilterOptionItem: FC<FilterOptionItemProps> = forwardRef(
  ({ option, isSelected, onSelection, containerRef }) => (
    <li
      className={cn("right_check_label filter_item_web", {
        checked: isSelected
      })}
      ref={containerRef}
    >
      <label>
        <div className="filterSelectedContent">
          {option.icon && <FilterOptionIcon icon={option.icon} />}
          {option.label}
          {option.count && ` (${option.count})`}
        </div>
        <span className="check_circle">
          <input
            type="checkbox"
            onClick={e => {
              onSelection({ option, isLeaf: true }, e);
            }}
          />
        </span>
      </label>
    </li>
  )
);

export default FilterOptionItem;
