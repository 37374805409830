import { isTablet, isMobile as isMob, isMobileOnly } from "react-device-detect";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";
import split from "lodash/split";
import isString from "lodash/isString";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import some from "lodash/some";
import includes from "lodash/includes";
import size from "lodash/size";
import memoize from "lodash/memoize";
import { ContextStorage } from "@/libs/contextStorage";

import {
  LANGUAGE_ROUTE_KEY,
  LANGUAGE_ROUTE_KEY_MAP,
  EXCLUDE_COUNTRY_SELECTION_DROPDOWN,
  BRAND_NAME_URL_SUFFIX,
  TAMARA,
  languageCountryRegExp,
  DEFAULT_VIEW_GRID_VALUE
} from "../constants";

import { selectRouteSlug, selectWishListGeneralProducts } from "./selectors";
import { DESKTOP, MOBILE, TABBY, TABLET } from "../constants";
import { getDataAsObject } from "./common";
import {
  PREFERRED_LANGUAGE,
  UNIQUE_ID,
  VIEW_GRID,
  VISITOR_ID
} from "../redux/constants";
import { getDefaultCookieOptions } from "./browserStorage";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const isIpadInSafari = () => {
  if (isServer) return false;
  if (navigator.userAgent.match(/iPad/i)) {
    return true;
  } else if (
    navigator.userAgent.match(/Mac/i) &&
    navigator.maxTouchPoints > 0 &&
    !isMob
  ) {
    return true;
  }
  return false;
};

export const getDeviceType = () => {
  if (isIpadInSafari() || isTablet) {
    return TABLET;
  } else if (isMobileOnly) {
    return MOBILE;
  } else {
    return DESKTOP;
  }
};

export const isMobile = {
  Android: !isServer ? () => navigator.userAgent.match(/Android/i) : () => {},
  BlackBerry: !isServer
    ? () => navigator.userAgent.match(/BlackBerry/i)
    : () => {},
  iOS: !isServer
    ? () =>
        (navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
          window.orientation === 90) ||
        (navigator.userAgent.match(/Macintosh/i) &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 1)
    : () => {},
  Opera: !isServer ? () => navigator.userAgent.match(/Opera Mini/i) : () => {},
  Windows: !isServer ? () => navigator.userAgent.match(/IEMobile/i) : () => {},
  Tablet: !isServer
    ? () => navigator.userAgent.match(/Tablet/i) && window.orientation === 90
    : () => {},
  WindowsTablet: !isServer
    ? () => navigator.userAgent.match(/Tablet PC/i) && window.orientation === 90
    : () => {},
  otherMobileDevice: !isServer
    ? () => navigator.userAgent.match(/Mobi/i)
    : () => {},
  any: !isServer
    ? () =>
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows() ||
        isMobile.Tablet() ||
        isMobile.WindowsTablet() ||
        isMobile.otherMobileDevice() ||
        isMob
    : () => {}
};

export const isMobileFooterView = () =>
  isMobile.any() &&
  isMobile.any().length &&
  window &&
  window.screen.width <= 834;

export const isTabletView = () => {
  const tabletViewTag = !isServer
    ? /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase()
      ) || isTablet
    : false;
  return tabletViewTag;
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const checkVideoURL = url => url.match(/\.(mp4)$/) !== null;

export const isArray = value =>
  value && typeof value === "object" && value.constructor === Array;

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
export const getUserIP = onNewIP => {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection =
    !isServer &&
    (window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection);
  var pc =
      !isServer &&
      new myPeerConnection({
        iceServers: []
      }),
    noop = function () {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split("\n").forEach(function (line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
};

export const isProduction = () => {
  return process.env.REACT_APP_ENV === "production" ? true : false;
};

export const encodeSpace = name => {
  try {
    return name.replace(/ /g, "%20");
  } catch (error) {
    return name;
  }
};

export const getProductUrlName = (name = "") => {
  let urlName = name && name.replace(/\s+/g, "-").toLowerCase();
  urlName = urlName && urlName.replace(/&/g, "and");
  urlName = urlName && urlName.replace(/\\|\/|\|/g, "-");
  urlName = urlName && urlName.replace(/[`=*^%$#@!<>?[\]{}(),;~'".+]/g, "");
  urlName = urlName && urlName.replace(/(\b)?(\s+)(\b)?/g, "");

  return urlName;
};

export const getProductUrl = (product, withOriginLocation = true) => {
  const state = ContextStorage.store.getState();
  const routeSlug = selectRouteSlug(state);

  const urlName = getProductUrlName(product.title);
  const originLocation = withOriginLocation
    ? `${document.location.origin}`
    : "";

  const url = `${originLocation}/${routeSlug}/${urlName}/${product.id}/p/`;

  return url;
};

export const getBaseUrl = props => {
  const { language, commonSettings } = props;
  const countrySHORT = get(commonSettings, "countrySHORT", "ae").toLowerCase();

  return `/${language}-${countrySHORT}`;
};

export const getProductBrandUrl = product => {
  const brandSlug = product.brand?.key;
  if (!brandSlug) {
    throw new Error(`Missing required parameter 'brand.key'`);
  }

  return `${BRAND_NAME_URL_SUFFIX}/${brandSlug}/`;
};

export const removeSpecialCharacters = (name = "") => {
  let urlName = name && name.replace(/-/g, " ").toLowerCase();
  return urlName;
};

export const RoundOffPoints = points => {
  var with2Decimals = points.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
};

export const globalScrollHandler = (ref, apply) => {
  if (apply) {
    ref.ownerDocument.body.setAttribute(
      "style",
      "overflow:hidden; position:fixed; top:0; left:0; width:100%; height:100%"
    );
  } else {
    if (ref && ref.ownerDocument) {
      ref.ownerDocument.body.removeAttribute("style");
    }
  }
};

export const handleScrollTop = directSmooth => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: directSmooth
  });
};

export const languageFromPathName = data => {
  let language = LANGUAGE_ROUTE_KEY.english;
  if (
    data &&
    (data.includes(`${LANGUAGE_ROUTE_KEY.arabic}-`) ||
      data.includes(`${LANGUAGE_ROUTE_KEY.arabic}`))
  ) {
    language = LANGUAGE_ROUTE_KEY.arabic;
  }
  return language;
};

export const countryFromPathName = data => {
  return data && data.split("-")[1].replace("/", "");
};

export const getUrlBasedLanguage = url =>
  languageFromPathName((url.match(languageCountryRegExp) || [])[0]);

export const getUrlBasedCountryCode = url =>
  countryFromPathName((url.match(languageCountryRegExp) || [])[0]);

export const isAvailableInCountry = (countryArray, countryId) =>
  Boolean(
    countryArray &&
      getDataAsObject(countryArray).find(c => c.countryId === countryId)
  );

export const selectedCountryName = (countryArray, countryId) => {
  const selectedCountry =
    countryArray &&
    countryId &&
    countryArray.filter(c => c.countryId === countryId);
  return selectedCountry && selectedCountry[0].countryName;
};

export const addAnimate = (
  item,
  isClicked,
  wishList,
  addClass,
  selectedSize,
  isDetailPage = false
) => {
  const isPresent = some(
    wishList,
    wishItem =>
      wishItem.productId === item.id &&
      (isDetailPage
        ? selectedSize === wishItem.size
        : selectedSize || "" === wishItem.size)
  );
  if (!isPresent && isClicked) {
    addClass = "addAnimation";
  } else if (isPresent && !isClicked) {
    addClass = "";
  } else if (isPresent && isClicked) {
    addClass = "removeAnimation";
  } else if (!isPresent && !isClicked) {
    addClass = "";
  }
  return addClass;
};

// Don't use if your validation depends on _priceChange value - it's commented and always false
// TODO: remove after refactoring cart and checkout page
export const validateProduct = (checkout, item) => {
  const OrderItems = checkout &&
    checkout.orderItems &&
    Object.keys(checkout.orderItems).length && [...checkout.orderItems];
  let _OutOfStock = false,
    _priceChange = false,
    _quantityChanged = false,
    _isAvailableCountry = true;
  map(OrderItems, orderItem => {
    if (orderItem.isAvailableInCountry === false) {
      _isAvailableCountry = false;
    }
  });
  if (item && item.size && item.size.length > 0) {
    const selectedColorsItem =
      item &&
      item.colors &&
      item.colors.colorSizeOptions &&
      item.colors.colorSizeOptions.find(colorsItem => {
        if (item.IsMultisize) {
          const colorItemSizeinfo =
            (colorsItem.sizeinfo && getDataAsObject(colorsItem.sizeinfo)) ||
            null;
          if (colorItemSizeinfo) {
            return colorItemSizeinfo.find(sizeInfoItem => {
              return sizeInfoItem.sizeGroupOptions.find(sizeGroupOptions => {
                return sizeGroupOptions.sizes.trim() === item.size.trim();
              });
            });
          }
        } else {
          const sizeLabel = get(colorsItem, "sizeLabel", false);

          return sizeLabel && sizeLabel.trim() === item.size.trim();
        }
        return null;
      });
    if (selectedColorsItem && selectedColorsItem.sizeStock <= 0) {
      _OutOfStock = true;
    }
  } else if (item && item.pstock <= 0) {
    _OutOfStock = true;
  }
  OrderItems &&
    OrderItems.length &&
    OrderItems.forEach(orderItem => {
      if (
        item.productId === orderItem.productId &&
        item.size === orderItem.size
      ) {
        if (orderItem.currentStock === 0) {
          _OutOfStock = true;
        } else if (item.prevValue && orderItem.currentStock < item.prevValue) {
          _quantityChanged = true;
        }
        /*Removed this check on request of sunil BFL-1096*/
        /*else if (orderItem.transactionPrice !== orderItem.currentPrice) {
        _priceChange = true
      }*/
      }
    });
  return {
    _OutOfStock: _OutOfStock,
    _priceChange: _priceChange,
    _quantityChanged: _quantityChanged,
    _isAvailableCountry: _isAvailableCountry
  };
};

export const itemsInCart = cartItems => {
  if (!cartItems) return 0;
  return cartItems.reduce((total, item) => {
    return (total += +get(item, "quantity", 0));
  }, 0);
};

export const standartQueriesList = {
  "hierarchicalCategories.lvl0": "categories-0",
  "hierarchicalCategories.lvl1": "categories-1",
  "hierarchicalCategories.lvl2": "categories-2",
  "attributes.Ideal For": "ideal_for",
  "attributes.Occasion": "occasion",
  "attributes.Color Family": "color",
  brandName: "brand",
  IsNew: "new",
  "sizeHierarchicalFilter.lvl2": "size",
  "attributes.Sportswear Type": "sportswear_type",
  "attributes.Top Style": "top_style",
  "attributes.Dress Style": "dress_style",
  "attributes.Jumpsuit Type": "jumpsuit_style",
  "attributes.Jacket style": "jacket_style",
  "attributes.Sweater Style": "sweater_style",
  "attributes.Lingerie Type": "lingerir_type",
  "attributes.Bottom Fit": "bottom_fit",
  "attributes.Type of Sport": "type_sport",
  "attributes.Type of Short": "type_short",
  "attributes.Type of Skirt": "type_skirt",
  "attributes.Type of Sleepwear": "type_sleepwear",
  "attributes.Type of Swimwear": "type_swimwear",
  "attributes.Types of Watch": "type_watch",
  "attributes.Types of Kitchen": "type_kitchen",
  "attributes.Shirt Fit": "shirt_fit",
  "attributes.Short Style": "short_style",
  "attributes.Sleepwear Type": "type_sleepwear",
  "attributes.Suit Type": "type_suit",
  "attributes.Suit Fit": "suit_fit",
  "attributes.Style": "style",
  "attributes.Toe Style": "toe_style",
  "attributes.Underwear Style": "underwear_style",
  "attributes.Bedding Type": "bedding_type",
  "attributes.Bath Accessories": "bath_accessories",
  "attributes.Type of Decoration": "decoration_type",
  "attributes.Decoration": "deocration",
  "attributes.Heel Style": "heel_style",
  "attributes.Type of Flooring": "flooring_type",
  "attributes.Sleeve Length": "sleeve_length",
  "attributes.Types of Neckline & Collar": "neckline_collar",
  "attributes.Age Recommended": "recommended_age",
  "attributes.Toys Age Group": "toys_age_group",
  "attributes.Type of Action Toys": "type_actiontoys",
  "attributes.Type of Arts & Creative Play": "type_arts_creativeplay",
  "attributes.Type of Baby & Pre-School Toys": "type_baby_preschooltoys",
  "attributes.Type of Construction Toys": "type_constructiontoys",
  "attributes.Type of Doll": "type_doll",
  "attributes.Type of Electronic Toys": "type_electronictoys",
  "attributes.Type of Games & Puzzles": "type_gamespuzzles",
  "attributes.Type of Outdoor Play": "type_outdoorplay",
  "attributes.Type of Pretend Play & Dressing Up": "type_pretend_dressingup",
  "attributes.Type of Soft Toys": "type_softtoys",
  "attributes.Type of Vehicles & Remote Control Toys":
    "type_vehicleremotecontroltoys",
  "attributes.Storage": "storage",
  "attributes.Shoe Width": "shoe_width",
  "attributes.Shoe Closure": "shoe_closure",
  "attributes.Bag Strap/Handle": "bag_straphandle",
  "attributes.Bag Lining Material": "bag_liningmaterial",
  "attributes.Concentration": "concentration",
  "attributes.Dial Shape": "dial_shape",
  "attributes.Watch Feature": "watch_feature",
  "attributes.Type of Kitchen": "kitchen_type",
  "attributes.Bed Size": "bed_size",
  "attributes.Frame Type": "frame_type",
  "attributes.Frame Shape": "frame_shape",
  "attributes.Lens Feature": "lens_feature",
  "attributes.Lens Material": "lens_material",
  "attributes.For Gift": "forgift",
  promotion: "promotion",
  store: "store",
  page: "page",
  sort: "sort",
  min: "min",
  max: "max"
};

export const filterMap = {
  "hierarchicalCategories.lvl0": "categories-0",
  "hierarchicalCategories.lvl1": "categories-1",
  "hierarchicalCategories.lvl2": "categories-2",
  "attributes.Ideal For": "ideal_for",
  "attributes.Occasion": "occasion",
  "attributes.Color Family": "color",
  brandName: "brand",
  IsNew: "new",
  "sizeHierarchicalFilter.lvl2": "size",
  "attributes.Sportswear Type": "sportswear_type",
  "attributes.Top Style": "top_style",
  "attributes.Dress Style": "dress_style",
  "attributes.Jumpsuit Type": "jumpsuit_style",
  "attributes.Jacket style": "jacket_style",
  "attributes.Sweater Style": "sweater_style",
  "attributes.Lingerie Type": "lingerir_type",
  "attributes.Bottom Fit": "bottom_fit",
  "attributes.Type of Sport": "type_sport",
  "attributes.Type of Short": "type_short",
  "attributes.Type of Skirt": "type_skirt",
  "attributes.Type of Sleepwear": "type_sleepwear",
  "attributes.Type of Swimwear": "type_swimwear",
  "attributes.Types of Watch": "type_watch",
  "attributes.Types of Kitchen": "type_kitchen",
  "attributes.Shirt Fit": "shirt_fit",
  "attributes.Short Style": "short_style",
  "attributes.Sleepwear Type": "type_sleepwear",
  "attributes.Suit Type": "type_suit",
  "attributes.Suit Fit": "suit_fit",
  "attributes.Style": "style",
  "attributes.Toe Style": "toe_style",
  "attributes.Underwear Style": "underwear_style",
  "attributes.Bedding Type": "bedding_type",
  "attributes.Bath Accessories": "bath_accessories",
  "attributes.Type of Decoration": "decoration_type",
  "attributes.Decoration": "deocration",
  "attributes.Heel Style": "heel_style",
  "attributes.Type of Flooring": "flooring_type",
  "attributes.Sleeve Length": "sleeve_length",
  "attributes.Types of Neckline & Collar": "neckline_collar",
  "attributes.Age Recommended": "recommended_age",
  "attributes.Toys Age Group": "toys_age_group",
  "attributes.Type of Action Toys": "type_actiontoys",
  "attributes.Type of Arts & Creative Play": "type_arts_creativeplay",
  "attributes.Type of Baby & Pre-School Toys": "type_baby_preschooltoys",
  "attributes.Type of Construction Toys": "type_constructiontoys",
  "attributes.Type of Doll": "type_doll",
  "attributes.Type of Electronic Toys": "type_electronictoys",
  "attributes.Type of Games & Puzzles": "type_gamespuzzles",
  "attributes.Type of Outdoor Play": "type_outdoorplay",
  "attributes.Type of Pretend Play & Dressing Up": "type_pretend_dressingup",
  "attributes.Type of Soft Toys": "type_softtoys",
  "attributes.Type of Vehicles & Remote Control Toys":
    "type_vehicleremotecontroltoys",
  "attributes.Storage": "storage",
  "attributes.Shoe Width": "shoe_width",
  "attributes.Shoe Closure": "shoe_closure",
  "attributes.Bag Strap/Handle": "bag_straphandle",
  "attributes.Bag Lining Material": "bag_liningmaterial",
  "attributes.Concentration": "concentration",
  "attributes.Dial Shape": "dial_shape",
  "attributes.Watch Feature": "watch_feature",
  "attributes.Type of Kitchen": "kitchen_type",
  "attributes.Bed Size": "bed_size",
  "attributes.Frame Type": "frame_type",
  "attributes.Frame Shape": "frame_shape",
  "attributes.Lens Feature": "lens_feature",
  "attributes.Lens Material": "lens_material",
  "attributes.For Gift": "forgift"
};

export const deleteServiceQueries = url => {
  const [baseURL, cleanQueryParamsString] = url.split("?");
  const filterMapKeys = Object.keys(standartQueriesList);
  const filterMapValues = Object.values(standartQueriesList);

  if (!cleanQueryParamsString) {
    return baseURL;
  }

  const queryParamStringSplittedList = cleanQueryParamsString.split("&");

  const filterQueriesList = queryParamStringSplittedList.filter(
    paramString =>
      filterMapKeys.includes(paramString.split("=")[0]) ||
      filterMapValues.includes(paramString.split("=")[0])
  );

  if (!filterQueriesList.length) {
    return baseURL;
  }

  const filterQueriesString = filterQueriesList.join("&");
  const urlWithFilterQueries = `${baseURL}?${filterQueriesString}`;

  return urlWithFilterQueries;
};

export const urlFilterMap = url => {
  if (!(url && url.includes("?"))) {
    return url;
  }

  let _url = url.split("?");

  url = _url[1];

  url = url && url.replace("categories-0", "hierarchicalCategories.lvl0");
  url = url && url.replace("categories-1", "hierarchicalCategories.lvl1");
  url = url && url.replace("categories-2", "hierarchicalCategories.lvl2");
  url = url && url.replace("occasion=", "attributes.Occasion=");
  url = url && url.replace("brand=", "brandName=");
  url = url && url.replace("new=", "IsNew=");
  url =
    url && url.replace("ideal_for", encodeURIComponent("attributes.Ideal For"));
  url =
    url &&
    url.replace("color=", `${encodeURIComponent("attributes.Color Family")}=`);
  url =
    url &&
    url.replace("bottom_fit", encodeURIComponent("attributes.Bottom Fit"));
  url =
    url &&
    url.replace("dress_style", encodeURIComponent("attributes.Dress Style"));
  url =
    url &&
    url.replace("jacket_style", encodeURIComponent("attributes.Jacket style"));
  url =
    url &&
    url.replace(
      "jumpsuit_style",
      encodeURIComponent("attributes.Jumpsuit Type")
    );
  url =
    url &&
    url.replace(
      "sweater_style",
      encodeURIComponent("attributes.Sweater Style")
    );
  url =
    url &&
    url.replace(
      "lingerir_type",
      encodeURIComponent("attributes.Lingerie Type")
    );
  url =
    url &&
    url.replace(
      "sportswear_type",
      encodeURIComponent("attributes.Sportswear Type")
    );
  url =
    url &&
    url.replace("type_doll", encodeURIComponent("attributes.Type of Doll"));
  url =
    url &&
    url.replace("type_sport", encodeURIComponent("attributes.Type of Sport"));
  url =
    url &&
    url.replace("type_short", encodeURIComponent("attributes.Type of Short"));
  url =
    url &&
    url.replace("type_skirt", encodeURIComponent("attributes.Type of Skirt"));
  url =
    url &&
    url.replace(
      "flooring_type",
      encodeURIComponent("attributes.Type of Flooring")
    );
  url =
    url &&
    url.replace(
      "type_swimwear",
      encodeURIComponent("attributes.Type of Swimwear")
    );
  url =
    url &&
    url.replace(
      "type_softtoys",
      encodeURIComponent("attributes.Type of Soft Toys")
    );
  url =
    url &&
    url.replace(
      "type_sleepwear",
      encodeURIComponent("attributes.Type of Sleepwear")
    );
  url =
    url &&
    url.replace(
      "type_actiontoys",
      encodeURIComponent("attributes.Type of Action Toys")
    );
  url =
    url &&
    url.replace(
      "type_outdoorplay",
      encodeURIComponent("attributes.Type of Outdoor Play")
    );
  url =
    url &&
    url.replace(
      "type_gamespuzzles",
      encodeURIComponent("attributes.Type of Games & Puzzles")
    );
  url =
    url &&
    url.replace(
      "type_electronictoys",
      encodeURIComponent("attributes.Type of Electronic Toys")
    );
  url =
    url &&
    url.replace(
      "type_constructiontoys",
      encodeURIComponent("attributes.Type of Construction Toys")
    );
  url =
    url &&
    url.replace(
      "type_arts_creativeplay",
      encodeURIComponent("attributes.Type of Arts & Creative Play")
    );
  url =
    url &&
    url.replace(
      "type_baby_preschooltoys",
      encodeURIComponent("attributes.Type of Baby & Pre-School Toys")
    );
  url =
    url &&
    url.replace(
      "type_pretend_dressingup",
      encodeURIComponent("attributes.Type of Pretend Play & Dressing Up")
    );
  url =
    url &&
    url.replace("type_watch", encodeURIComponent("attributes.Types of Watch"));
  url =
    url &&
    url.replace(
      "type_kitchen",
      encodeURIComponent("attributes.Types of Kitchen")
    );
  url =
    url &&
    url.replace(
      "neckline_collar",
      encodeURIComponent("attributes.Types of Neckline & Collar")
    );
  url =
    url && url.replace("suit_fit", encodeURIComponent("attributes.Suit Fit"));
  url =
    url && url.replace("type_suit", encodeURIComponent("attributes.Suit Type"));
  url =
    url && url.replace("shirt_fit", encodeURIComponent("attributes.Shirt Fit"));
  url =
    url && url.replace("toe_style", encodeURIComponent("attributes.Toe Style"));
  url =
    url &&
    url.replace("short_style", encodeURIComponent("attributes.Short Style"));
  url =
    url &&
    url.replace("bedding_type", encodeURIComponent("attributes.Bedding Type"));
  url =
    url &&
    url.replace(
      "type_sleepwear",
      encodeURIComponent("attributes.Sleepwear Type")
    );
  url =
    url &&
    url.replace(
      "underwear_style",
      encodeURIComponent("attributes.Underwear Style")
    );
  url =
    url &&
    url.replace(
      "bath_accessories",
      encodeURIComponent("attributes.Bath Accessories")
    );
  url =
    url &&
    url.replace(
      "decoration_type",
      encodeURIComponent("attributes.Type of Decoration")
    );
  url = url && url.replace("storage=", "attributes.Storage=");
  url =
    url && url.replace("bed_size", encodeURIComponent("attributes.Bed Size"));
  url =
    url && url.replace("forgift", encodeURIComponent("attributes.For Gift"));
  url = url && url.replace("deocration=", "attributes.Decoration=");
  url =
    url &&
    url.replace("shoe_width", encodeURIComponent("attributes.Shoe Width"));
  url =
    url &&
    url.replace("heel_style", encodeURIComponent("attributes.Heel Style"));
  url =
    url &&
    url.replace("dial_shape", encodeURIComponent("attributes.Dial Shape"));
  url =
    url &&
    url.replace("frame_type", encodeURIComponent("attributes.Frame Type"));
  url =
    url &&
    url.replace("frame_shape", encodeURIComponent("attributes.Frame Shape"));
  url =
    url &&
    url.replace("shoe_closure", encodeURIComponent("attributes.Shoe Closure"));
  url =
    url &&
    url.replace("lens_feature", encodeURIComponent("attributes.Lens Feature"));
  url =
    url &&
    url.replace(
      "lens_material",
      encodeURIComponent("attributes.Lens Material")
    );
  url =
    url &&
    url.replace(
      "sleeve_length",
      encodeURIComponent("attributes.Sleeve Length")
    );
  url = url && url.replace("concentration=", "attributes.Concentration=");
  url =
    url &&
    url.replace(
      "watch_feature",
      encodeURIComponent("attributes.Watch Feature")
    );
  url =
    url &&
    url.replace(
      "recommended_age",
      encodeURIComponent("attributes.Age Recommended")
    );
  url =
    url &&
    url.replace(
      "toys_age_group",
      encodeURIComponent("attributes.Toys Age Group")
    );
  url =
    url &&
    url.replace(
      "kitchen_type",
      encodeURIComponent("attributes.Type of Kitchen")
    );
  url =
    url &&
    url.replace(
      "bag_straphandle",
      encodeURIComponent("attributes.Bag Strap/Handle")
    );
  url =
    url &&
    url.replace(
      "bag_liningmaterial",
      encodeURIComponent("attributes.Bag Lining Material")
    );
  url =
    url &&
    url.replace(
      "type_vehicleremotecontroltoys",
      encodeURIComponent("attributes.Type of Vehicles & Remote Control Toys")
    );
  url =
    url && url.replace("top_style", encodeURIComponent("attributes.Top Style"));
  url = url && url.replace("style=", "attributes.Style=");
  url = url && url.replace("size=", "sizeHierarchicalFilter.lvl2=");

  _url[1] = url;
  _url = _url.join("?");

  return _url;
};

export const setFilterOrder = array => {
  let categories = [];
  let attributes = [];
  try {
    var returnArray = array.filter(item => {
      if (item.includes("hierarchicalCategories")) {
        categories.push(item);
        return false;
      }
      if (item.includes("attributes")) {
        attributes.push(item);
        return false;
      }
      return true;
    });
    categories = categories.concat(returnArray);
    returnArray = categories.concat(attributes);
    return returnArray;
  } catch (error) {
    console.log(error);
  }
};

class UserAgentService {
  #isDesktopView = true;

  isDesktop = () => {
    return !!this.#isDesktopView;
  };

  setView = (userAgent = "") => {
    this.#isDesktopView = !(
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i) ||
      userAgent.match(/Tablet/i) ||
      userAgent.match(/Tablet PC/i)
    );
  };
}

export const selectedItemSizeId = (_item, sizeType, sizelabel) => {
  let selectedSize = {};
  if (_item.IsMultisize) {
    selectedSize =
      _item.colors &&
      _item.colors.colorSizeOptions &&
      _item.colors.colorSizeOptions.find(item => {
        const sizeInfo = Array.isArray(item.sizeinfo)
          ? item.sizeinfo
          : item.sizeinfo && getDataAsObject(item.sizeinfo);
        return (
          sizeInfo &&
          sizeInfo.length > 0 &&
          sizeInfo.find(sizeItem => {
            return (
              sizeItem.sizeGroupOptions &&
              sizeItem.sizeGroupOptions.find(item => {
                return (
                  item.sizeGroupName3 === sizeType &&
                  item.sizes.trim() === sizelabel
                );
              })
            );
          })
        );
      });
  } else {
    selectedSize =
      _item.colors &&
      _item.colors.colorSizeOptions &&
      _item.colors.colorSizeOptions.find(item => {
        if (item.sizeLabel) {
          return (item.sizeLabel && item.sizeLabel.trim()) === sizelabel;
        } else {
          return item;
        }
      });
  }
  return selectedSize;
};

export const _countryStoreSelection = (
  store,
  url,
  userCountry,
  common,
  setCommonData
) => {
  let _country = null,
    countryAE = {},
    selectedCountry = null,
    selectedIPCountry = null,
    countryCookie = null,
    countryData = common.countryData || [];

  countryData.map(_country => {
    if (
      url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
      url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
    ) {
      selectedCountry = _country;
    }
    if (_country.countrySHORT === userCountry) {
      countryCookie = _country;
    }
    if (_country.countrySHORT === "AE") {
      countryAE = _country;
    }
    if (
      common.ipCountryData &&
      common.ipCountryData.countrySHORT &&
      common.ipCountryData.countrySHORT === _country.countrySHORT
    ) {
      selectedIPCountry = _country;
    }
    return null;
  });

  if (selectedCountry) {
    _country = selectedCountry;
    store.dispatch(setCommonData(selectedCountry));
  } else if (!selectedCountry) {
    if (countryCookie) {
      _country = countryCookie;
      store.dispatch(setCommonData(countryCookie));
    } else if (selectedIPCountry) {
      _country = selectedIPCountry;
      store.dispatch(setCommonData(selectedIPCountry));
    } else {
      _country = countryAE;
      store.dispatch(setCommonData(countryAE));
    }
  }

  const _storeList = (_country && _country.stores) || [];
  let storeId = null,
    _bflStoreId = null,
    _storeMap = getStoreMap({ stores: _storeList });

  _storeList &&
    _storeList.forEach(item => {
      if (url.includes(_storeMap[item.mid])) {
        storeId = item.mid;
      }
      if (item.name.toLocaleLowerCase().includes("brands")) {
        _bflStoreId = item.mid;
      }
    });

  return { _country, storeId, _bflStoreId };
};

export const UserAgentManager = new UserAgentService();

const formatStoreDetails = (stores, index) => {
  const filteredStoreList = filter(stores, { status: "a", isShopPickup: true });
  return map(filteredStoreList, (store, index) => ({
    ...store,
    value: get(store, "name", ""),
    station_name: get(store, "name", ""),
    mapIconURL: get(store, "mapIconURL", ""),
    address_info: get(store, "address", ""),
    extra_info: get(store, "extraInfo", ""),
    station_id: get(store, "shopId", ""),
    collectionPointId: get(store, "shopId", ""),
    disableOption: get(store, "capacity", "") <= 0 ? true : false
  }));
};

export const getInstoreCityList = (storeList, countryId) => {
  const storeListByCountryId = filter(
    storeList,
    store => store.countryId === countryId
  );
  const mappedStoreList =
    map(storeListByCountryId, (list, index) => {
      return {
        shops: formatStoreDetails(get(list, "shops", [])),
        name: get(list, "city", ""),
        stateId: get(list, "stateId", ""),
        value: get(list, "city", ""),
        lat: get(list, "shops.0.lat", 25.238069),
        lng: get(list, "shops.0.lng", 55.273489)
      };
    }) || [];
  return filter(mappedStoreList, store => store.shops.length);
};

export const checkCountry = (key, currentCountryCode) => {
  if (key.indexOf(currentCountryCode) >= 0) {
    return true;
  }
  return false;
};

export const getBrand = (shopByStore, storeId, language) => {
  const storesArray = get(
    shopByStore,
    `${LANGUAGE_ROUTE_KEY_MAP[language]}["storeList"]`,
    []
  );
  const filteredBrand = storesArray.filter(store => storeId === +store.storeId);
  return filteredBrand[0] || {};
};

export const isArabic = language => language.includes("ar");

export const removeWhiteSpaces = text => {
  return text.split(" ").join("");
};

export const handleContinueShoppingButtonUrl = () => {
  let pathName = window.location.pathname;
  let splittedPathName = pathName.split("/");
  pathName = pathName.replace(splittedPathName[1] + "/", "");
  let searchQueryParam = window.location.search;
  let continueShoppingUrl = `${pathName}${searchQueryParam}`;
  localStorage.setItem("CONTINUE_SHOPPING_URL", continueShoppingUrl);
};

export const getColorHex = (colors, name, lang) => {
  const colorName = lang === "en" ? "en_name" : "ar_name";
  const colorObj = colors?.find(item => item[colorName] === name);

  return colorObj ? colorObj.value : "#ffffff";
};

export const removeCountrySelectionDropdown = history => {
  try {
    const excludeCountrySelectionDropdown = EXCLUDE_COUNTRY_SELECTION_DROPDOWN;
    const pathName = get(history, "location.pathname", "");
    const splittedPathName = split(pathName, "/");
    if (excludeCountrySelectionDropdown.indexOf(splittedPathName[2]) > -1) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getStoreMap = settings => {
  const _storeMap = {};
  settings &&
    settings.stores &&
    settings.stores.forEach(_store => {
      let _storeURL = _store.name;
      _storeURL = _storeURL.toLowerCase();
      _storeURL = _storeURL.includes("toys")
        ? "toys"
        : _storeURL.replace(/ /g, "-");
      _storeMap[_store.mid] = `/${_storeURL}`;
    });
  return _storeMap;
};

export const isTouchScreen = () =>
  !isServer && window.matchMedia("(max-width: 812px)").matches;

export const isMiddleScreen = () =>
  !isServer && window.matchMedia("(max-width: 1200px)").matches;

export const generateArrayOfYearStrings = (yearsAmount = 12) => {
  yearsAmount = +yearsAmount;
  if (!Number.isInteger(yearsAmount) || yearsAmount <= 0) {
    console.error("Function generateArrayOfYearStrings got incorrect argument");
    return null;
  }
  const min = new Date().getFullYear();
  const max = min + yearsAmount;
  let years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString());
  }
  return years;
};

export const includesSubString = (string, subString) =>
  string.toUpperCase().includes(subString.toUpperCase());

export const checkUrlSearchMultiCategory = historySearch =>
  (
    historySearch
      .split("&")
      .find(
        item =>
          item.includes("categories-2") ||
          item.includes("hierarchicalCategories.lvl2")
      ) || ""
  ).split(",").length > 1;

export const getCountryNameFromUrl = (url = "") => {
  const regExp = /(en|ar)-([^/]+)/;
  if (!isString(url) || !url.match(regExp)) {
    return "";
  }
  const match = url.match(regExp);
  return match[2];
};

export const getSelectedFiltersWithItems = (productsObj, item) => {
  const selectedFacetsByItem = get(productsObj, ["selectedFacets", item]);
  const requestedFacets = get(productsObj, [item], []);
  const respondedFacets = get(productsObj, ["facets", item], {});

  return (selectedFacetsByItem || requestedFacets).filter(
    facet => respondedFacets[facet]
  );
};

export const getAvailableDisplaySizes = memoize(item => {
  const displayItems = get(item, "displaySizes", []);
  const uniqueDisplayItems = uniqBy(displayItems, "size");
  const sortedDisplayItems = sortBy(uniqueDisplayItems, ["sortOrder"]);
  return sortedDisplayItems;
});

export const filterAddressByState = (addressDetails, state) => {
  try {
    const allStateIds = map(state, "stateId");

    if (!size(state)) return addressDetails;

    return filter(addressDetails, address =>
      includes(allStateIds, parseInt(address.cityId))
    );
  } catch (error) {
    console.error("Failed to filter address details by state", error);
    return addressDetails;
  }
};

export const getWindowOrientation = () => {
  if (isServer) {
    console.error("window object is absent");
    return null;
  }
  const { innerWidth: width, innerHeight: height } = window;
  return width >= height ? "landscape" : "portrait";
};

// TODO: this is an old func use new one(below)
export const renderPaymentModeMessage = (
  paymentMode,
  paymentExtraInfo,
  translation
) => {
  try {
    const replaceTabbyAndTamara = paymentMode
      .join(",")
      .replace(TABBY, paymentExtraInfo)
      .replace(TAMARA, paymentExtraInfo)
      .split(",")
      .sort();
    return replaceTabbyAndTamara.map(mode => translation[mode]).join(", ");
  } catch (error) {
    return "";
  }
};

// TODO: Remove "New" after deleting renderPaymentModeMessage
export const renderPaymentModeMessage_New = (
  paymentMode,
  paymentExtraInfo,
  translation
) => {
  try {
    const replaceTabbyAndTamara = paymentMode
      .join(",")
      .replace(TABBY, paymentExtraInfo)
      .replace(TAMARA, paymentExtraInfo)
      .split(",")
      .sort();
    return replaceTabbyAndTamara.map(mode => translation([mode])).join(", ");
  } catch (error) {
    return "";
  }
};

export const getShippingLimitMessage = (option, myCartTranslation) => {
  switch (option.freeShippingLimit && option.deliveryType) {
    case "SELF_PICKUP":
      return myCartTranslation.yourOrderIsEligibleForFreeCollection;
    case "DELIVERY_ADDRESS":
      return myCartTranslation.yourOrderIsEligibleForFreeShipping;
    case "STORE_PICKUP":
      return myCartTranslation.yourOrderIsEligibleForFreeInstorePickup;
    default:
      return "";
  }
};

export const getOptionalByValue = ({ value, keyName, callback = null }) =>
  value && { [keyName]: callback ? callback(value) : value };

export const getWishListGeneralDataStore = () =>
  selectWishListGeneralProducts(ContextStorage.store.getState());

export const isPresent = item => {
  const wishListBaseData = getWishListGeneralDataStore();
  return wishListBaseData?.some(
    el => el.productId === item.productId && (item.size || "" === el.size)
  );
};

export const getUniqueId = () => {
  const uniqueId = ContextStorage.cookies.get(UNIQUE_ID);
  if (uniqueId) return uniqueId;

  const newUniqueId = generateUUID();
  storeUniqueId(newUniqueId);

  return newUniqueId;
};

export const storeUniqueId = uniqueId => {
  ContextStorage.cookies.set(UNIQUE_ID, uniqueId, getDefaultCookieOptions());
};

export const setViewGrid = grid => {
  ContextStorage.cookies.set(VIEW_GRID, grid, getDefaultCookieOptions());
};

export const getViewGrid = () => {
  const viewGrid = ContextStorage.cookies.get(VIEW_GRID);
  if (viewGrid) return Number(viewGrid);

  setViewGrid(DEFAULT_VIEW_GRID_VALUE);
  return DEFAULT_VIEW_GRID_VALUE;
};

export const getVisitorId = () => {
  const visitorId = ContextStorage.cookies.get(VISITOR_ID);

  if (visitorId) return visitorId;

  const newVisitorId = generateUUID();
  ContextStorage.cookies.set(
    VISITOR_ID,
    newVisitorId,
    getDefaultCookieOptions()
  );

  return newVisitorId;
};

export const updateVisitorId = id => {
  const visitorId = id || generateUUID();
  ContextStorage.cookies.set(VISITOR_ID, visitorId, getDefaultCookieOptions());

  return visitorId;
};
//TODO: this is hotfix, remove this after implemented in stategy
import { BROWSER_STORAGE } from "../redux/constants";

export const getCountryId = countryShort => {
  const countryShortFromCookies = ContextStorage.cookies.get(
    BROWSER_STORAGE.USER_COUNTRY
  );

  if (!countryShortFromCookies && !countryShort) return null;

  const countryIds = {
    AE: 236,
    SA: 199,
    LB: 130,
    IN: 105,
    OM: 174,
    QA: 186,
    BH: 17,
    KW: 126,
    SG: 204,
    MY: 141
  };

  return countryIds[countryShort || countryShortFromCookies];
};

export const getAcceptablePhoneLengths = countrySetting => {
  if (!countrySetting) return [];
  return countrySetting.mobileNumberCriteria?.acceptableLength?.length
    ? countrySetting.mobileNumberCriteria.acceptableLength
    : [countrySetting.mobileLocalNumberLength];
};

export const getPreferredLanguage = () => {
  const preferredLanguage = ContextStorage.cookies.get(PREFERRED_LANGUAGE);
  if (!preferredLanguage) {
    setPreferredLanguage("en");
  }
  return preferredLanguage;
};

export const setPreferredLanguage = shortLanguage => {
  ContextStorage.cookies.set(
    PREFERRED_LANGUAGE,
    shortLanguage,
    getDefaultCookieOptions()
  );
};
