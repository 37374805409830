import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import cn from "clsx";

import { PLP_CMS_DATA_SLUGS } from "../../constants";
import Page from "../../components/page";
import View from "../../components/common/view";
import ProductListingPagination from "./productListingPagination";
import Breadcrumbs from "./breadcrumbs";
import { useIsRTL } from "../../hooks";
import { getViewGrid, handleContinueShoppingButtonUrl } from "../../util";
import { useAppSelector } from "../../redux/store";
import { selectCommonSettings } from "../../util/selectors";
import { getMetaTitleDescription } from "../../util/seo";
import { AnalyticService } from "@/services";
import { getSubCatFromUrl } from "@/services/analytic-service/utils";
import useProductListingFilters from "./hooks/useProductListingFilters";
import ProductListingFiltersMob from "./productListingFiltersMob";
import SectionsWrapper from "./sectionsWrapper";
import { withPageInitialLoad } from "@/libs/pageDataBridge";
import WebFilteringSection from "./webFilteringSection";
import { BreadCrumbData } from "@/redux/features/dynamicPage";
import { handleGridChange } from "@/redux/actions/productListing.action";
import PageSections from "./pageSections";
import { runSafely } from "@/util/common";
import { SECTION_PLP_TYPES, SECTION_SLUGS } from "@/constants/sections";
import { SectionService } from "@/services";
import { getSplittedSections } from "@/util/sections";
import ListItemWrapper from "./listItemWrapper";
import ContentHeader from "./contentHeader";

import { MetaData, ProductsListingPageData } from "./types";
import { Content } from "../catalogPage/types";
import "./style.scss";

const FIRST_PAGE = 1;

const MobContentHeader: FC<{ heading: string }> = ({ heading }) => (
  <h1 className="content_header_mobile">{heading}</h1>
);

const ProductListing: FC<{
  providedListingData: ProductsListingPageData;
  metaData: MetaData;
  breadcrumbs: BreadCrumbData[];
  plpSections?: Content[];
  subtype: string;
}> = ({ providedListingData, metaData, breadcrumbs, plpSections }) => {
  const listingFilters = useProductListingFilters(providedListingData);
  const history = useHistory();
  const location = useLocation();
  const commonSettings = useAppSelector(selectCommonSettings);
  const isRTL = useIsRTL();
  const products = providedListingData?.hits;
  const activePage = providedListingData?.page ?? 1;
  const pagesTotal = providedListingData?.nbPages;
  const itemsTotal = providedListingData?.nbHits;
  const headingH1 = providedListingData?.headingH1;
  const headingEn = providedListingData?.en_title;

  const formattedSections =
    plpSections &&
    getSplittedSections(plpSections, SECTION_PLP_TYPES, SECTION_PLP_TYPES);

  const handlePaginationClick = useCallback(
    (page: { selected: number }) => {
      handleContinueShoppingButtonUrl();
      const currentPage = page.selected + 1;
      const currentParams = qs.parse(location.search);

      if (currentPage === FIRST_PAGE) {
        delete currentParams.page;
      } else {
        currentParams.page = `${currentPage}`;
      }

      history.push({ search: qs.stringify(currentParams) });
    },
    [history, location.search]
  );

  const { title: metaTitle, description } = useMemo(
    () => getMetaTitleDescription(metaData, history, commonSettings),
    [metaData, history, commonSettings]
  );

  useEffect(() => {
    AnalyticService.product.trackContentListing({
      item: products,
      position: activePage,
      title: headingEn,
      category: headingEn,
      productListCount: itemsTotal
    });
    if (getSubCatFromUrl(providedListingData.url)) {
      const { parentCategory, category, subCategory } = getSubCatFromUrl(
        providedListingData.url
      );
      AnalyticService.common.trackViewSubCategory({
        parentCategory,
        category,
        subCategory
      });
    }
  }, []);

  return (
    <>
      <PageSections sections={formattedSections?.[SECTION_PLP_TYPES.PLP_TOP]} />
      <Page
        title={metaTitle}
        description={description}
        id="product-listing"
        className={cn("product_listing_page", { arabic: isRTL })}
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <View.mobile>
          <MobContentHeader heading={headingH1} />
          <ProductListingFiltersMob
            selectedFiltersData={providedListingData.selectedFilters}
            filters={listingFilters}
            itemsTotal={providedListingData.nbHits}
            appliedFilterCount={providedListingData.appliedFilterCount}
            listingPageData={providedListingData}
          />
        </View.mobile>

        <div className="header_section">
          <ContentHeader
            isCategoryTitle={true}
            itemsTotal={itemsTotal}
            headingH1={headingH1}
            productSearchTitle={""}
            currentSelectedCategory={""}
          />
          <SectionsWrapper sectionSlug={PLP_CMS_DATA_SLUGS.TOP_SECTION} />
        </div>
        <WebFilteringSection
          listingPageData={providedListingData}
          listingFilters={listingFilters}
        />
        <algoliaPLPContext.Provider
          value={{
            algoliaIndex: providedListingData.index,
            algoliaQueryId: providedListingData.queryID
          }}
        >
          <ListItemWrapper
            products={providedListingData.hits}
            listHeading={headingEn || headingH1}
          />
        </algoliaPLPContext.Provider>
        <emptyResultsSectionsContext.Provider
          value={formattedSections?.[SECTION_PLP_TYPES.EMPTY_RESULTS]}
        >
          <ProductListingPagination
            showPageCount={!!products?.length}
            activePage={activePage}
            pagesTotal={pagesTotal}
            handlePageClick={handlePaginationClick}
          />
        </emptyResultsSectionsContext.Provider>
        <SectionsWrapper sectionSlug={PLP_CMS_DATA_SLUGS.BOTTOM_SECTION} />
      </Page>
    </>
  );
};

export const emptyResultsSectionsContext = createContext<Content[]>([]);
export const algoliaPLPContext = createContext({
  algoliaIndex: "",
  algoliaQueryId: ""
});

export default withPageInitialLoad({
  pageName: props => cn({ [props.subtype]: props.subtype }, "plp"),
  page: ProductListing,
  strategy: async ({ store }) => {
    store.dispatch(handleGridChange(getViewGrid()));

    const response = await runSafely(
      async () => await SectionService.getBannerSections(SECTION_SLUGS.PLP)
    );

    return { plpSections: response?.data as Content[] };
  },
  ssr: true
});
