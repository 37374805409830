import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { Scrollbars } from "react-custom-scrollbars";
import { getAllSizes } from "../productListingFiltersWeb/filterSizesWeb";
import FilterWrapperElement from "./filterWrapperElement";
import { selectedFiltersContext } from ".";
import useSelectedByQuery from "../hooks/useSelectedByQuery";
import { ApplyButton } from "./applyButton";
import { FilterOption, FilterType, GeneralFacet } from "../types";

const FilterSizeMob: FC<{
  filter: GeneralFacet & { data: FilterOption[] };
  setToggledFilter: (filter: FilterType | EmptyObject) => void;
  toggledFilter: FilterType;
}> = ({ filter, setToggledFilter, toggledFilter }) => {
  const { t } = useTranslation("productListing");
  const [activeSizeGroup, setActiveSizeGroup] = useState(null);
  const [screenHeight, setScreenHeight] = useState(0);
  const [activeSizeStyle, setActiveSizeStyle] = useState(null);
  const [activeSizeFormat, setActiveSizeFormat] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [isShowSizes, setIsShowSizes] = useState(false);
  const [labelForFilterMenu, setLabelForFilterMenu] = useState("");

  const { onSelection, updateSelectedFilters } = useContext(
    selectedFiltersContext
  );
  const selectedByQuery = useSelectedByQuery();

  const getDeepestChildren = group =>
    group.children[0].children
      ? group.children.flatMap(child => getDeepestChildren(child))
      : group.children;

  const setSelectedFilterLabels = () => {
    const allSizes = filter.data.flatMap(sizeGroup =>
      getDeepestChildren(sizeGroup)
    );
    const selectedSizes = allSizes.filter(size => size.isSelected);
    const labelFromSelectedFilters = selectedSizes.map(({ label }) => label);
    const labelForFilterMenu = uniq(labelFromSelectedFilters).join(", ");

    setLabelForFilterMenu(labelForFilterMenu);
  };

  useEffect(() => {
    const sizesByQuery = selectedByQuery[filter.queryParam]?.selectedOptions;
    if (!isEmpty(sizesByQuery)) {
      const allSizes = getAllSizes(filter);
      const selectedSizes = allSizes.filter(size => size.isSelected);
      updateSelectedFilters({
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: selectedSizes
      });
      setSelectedFilterLabels();
      setSelectedSizes(selectedSizes);
    }
  }, []);

  useEffect(() => {
    if (activeSizeGroup) {
      const sizeFormat = activeSizeStyle.children[0];
      setActiveSizeFormat(sizeFormat);
    }
  }, [activeSizeStyle]);

  useEffect(() => {
    if (!toggledFilter) {
      setSelectedSizes([]);
    }
    setSelectedFilterLabels();
  }, [filter]);

  const onSizeSelection = useCallback(
    (size, e) => {
      e.stopPropagation();
      let updatedSelectedSizes = [];
      if (
        selectedSizes &&
        selectedSizes.find(selectedSizes => selectedSizes.key === size.key)
      ) {
        updatedSelectedSizes = selectedSizes.filter(
          selectedSize => selectedSize.key !== size.key
        );
      } else {
        updatedSelectedSizes = [...selectedSizes, size];
      }

      setSelectedSizes(updatedSelectedSizes);
    },
    [selectedSizes, filter, onSelection]
  );

  const isCurrentToggledFilter =
    toggledFilter && toggledFilter.queryParam === filter.queryParam;

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  const checkIfActive = (item, activeItem) => {
    if (Array.isArray(activeItem)) {
      return activeItem.some(i => i.key === item.key);
    } else {
      return item === activeItem;
    }
  };

  const onSizeWrapperOptionClick = option => {
    const defaultOption = option.children[0];
    setActiveSizeGroup(option);
    setActiveSizeStyle(defaultOption);
    setActiveSizeFormat(defaultOption.children[0]);
    setIsShowSizes(true);
  };

  const onApplyButtonClickHandler = async e => {
    e.stopPropagation();
    const selectedSizesByQuery =
      selectedByQuery[filter.queryParam]?.selectedOptions || [];

    const selectedSizesKeys = selectedSizes.map(size => ({ key: size.key }));

    if (!isEqual(selectedSizesKeys, selectedSizesByQuery)) {
      await onSelection({
        ...filter,
        selectedOptions: selectedSizes
      });
    }
    setToggledFilter({});
    setIsShowSizes(false);
  };

  const onFilterClearClickHandler = () => {
    if (!isEmpty(selectedByQuery)) {
      onSelection({
        ...filter,
        selectedOptions: []
      });
    }

    setSelectedSizes([]);
    setLabelForFilterMenu("");
  };

  const onBackButtonClick = e => {
    e.stopPropagation();
    if (isShowSizes) {
      const allSizes = getAllSizes(filter);
      const selectedSizes = allSizes.filter(size => size.isSelected);
      setSelectedSizes(selectedSizes ? selectedSizes : []);
      setIsShowSizes(false);
    } else {
      setToggledFilter({});
      setSelectedFilterLabels();
    }
  };

  const SizeWrapper = () => (
    <ul className="size_wrapper">
      {sortBy(toggledFilter.data, ["sortOrder"]).map((option, key) => {
        return (
          <li
            className="filter_item_mobile size_filter"
            key={key}
            onClick={() => onSizeWrapperOptionClick(option)}
          >
            <div>
              {option.label && <p className="size_text">{option.label}</p>}
              <p className="selected_sizes">{t("all")}</p>
            </div>
            <span className="arrow_button"></span>
          </li>
        );
      })}
    </ul>
  );

  const LeftFilterPanel = () => (
    <div className="filterLeftPane">
      <ul>
        {sortBy(activeSizeGroup.children, ["sortOrder"]).map(item => (
          <li
            key={item.key}
            className={cn({
              active: activeSizeStyle?.key === item.key
            })}
            onClick={() => {
              setActiveSizeStyle(item);
              setActiveSizeFormat(item.children[0]);
            }}
          >
            {item.label && <p>{item.label}</p>}
          </li>
        ))}
      </ul>
    </div>
  );

  const RightFilterPanel = () => (
    <div className="filterRightPane">
      <ul className="rightTab">
        {activeSizeStyle &&
          sortBy(activeSizeStyle.children, ["sortOrder"]).map((item, index) => (
            <li
              key={item.key}
              className={cn({
                active:
                  activeSizeFormat === item ||
                  (!activeSizeFormat && index === 0)
              })}
              onClick={() => setActiveSizeFormat(item)}
            >
              {item.label && <p>{item.label}</p>}
            </li>
          ))}
      </ul>
      <ul className="rightFilterContent" style={{ height: screenHeight - 151 }}>
        {sortBy(activeSizeFormat.children, ["sortOrder"]).map(item => (
          <li key={item.key}>
            <p onClick={e => onSizeSelection(item, e)}>
              <span
                className={cn("checkbox", {
                  checked: checkIfActive(item, selectedSizes)
                })}
              />
              {item.label && <span className="text">{item.label}</span>}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <h4
      className={cn(
        "filter_title",
        isCurrentToggledFilter ? "filter_open" : "filter_close_inner"
      )}
      onClick={() => setToggledFilter(filter)}
    >
      {filter?.label && (
        <span>
          {filter.label}
          {labelForFilterMenu && <strong>{labelForFilterMenu}</strong>}
        </span>
      )}

      {isCurrentToggledFilter && (
        <div
          className="filter_content openLeft"
          onClick={e => e.stopPropagation()}
        >
          <FilterWrapperElement
            iconClassName="mobile_back_icon"
            onIconClick={onBackButtonClick}
            filterTitle={filter.label}
            onClearBtnClick={onFilterClearClickHandler}
            isHeaderVisible={isCurrentToggledFilter}
          >
            <Scrollbars
              autoHeight
              autoHeightMin={55}
              autoHeightMax={`calc(100vh - 120px)`}
            >
              {!isShowSizes ? (
                <SizeWrapper />
              ) : (
                <ul className="size_chart">
                  <div
                    className="sizeFilterWrapper"
                    style={{ height: screenHeight - 50 }}
                  >
                    <div
                      className="sizeFilter_tabgroup"
                      style={{ height: screenHeight - 101 }}
                    >
                      <LeftFilterPanel />
                      <RightFilterPanel />
                    </div>
                  </div>
                </ul>
              )}
            </Scrollbars>
            {isCurrentToggledFilter && isShowSizes && (
              <ApplyButton
                onButtonClick={onApplyButtonClickHandler}
                buttonText={t("apply")}
                wrapperStyles={{ width: "100%" }}
              />
            )}
          </FilterWrapperElement>
        </div>
      )}
    </h4>
  );
};

export default FilterSizeMob;
