import Visa from "../../images/visa.gif";
import Mastercard from "../../images/mastercard.gif";
import Cod from "../../images/cod.png";
import Amex from "../../images/amex.gif";
import VisaPay from "../../images/visa-pay.gif";
import ApplePay from "../../images/apple-pay.gif";

export const COUNTRY_BASED_PAYMENT = {
  creditOrDebitCard: [Mastercard, Visa],
  applePay: ApplePay,
  visaPay: VisaPay,
  amex: Amex,
  cod: Cod,
  MASTERCARD: Mastercard,
  VISA: Visa,
  APPLE_PAY: ApplePay,
  COD: Cod
};
