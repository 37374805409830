import View from "@/components/common/view";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import qs from "query-string";

import AssistiveFiltersSection from "../assistiveFilters";
import ProductListingFilters from "../productListingFiltersWeb";
import FilteredBy from "../filteredBy";
import useAssistiveFilters from "../hooks/useAssistiveFilters";
import useSelectedByQuery from "../hooks/useSelectedByQuery";
import PlpAnalytics from "@/services/analytics/main/plp";
import { SORT_LOCATION } from "@/services/analytics/main/plp/types";
import { handleFilterEvent } from "@/routes/productListing/analyticsHelper";

import { FILTER_QUERY_PARAMS, FILTER_TYPES } from "../constants";
import { useRouteSlug } from "@/hooks";
import useSelectedFilters from "../hooks/useSelectedFilters";
import {
  AssistiveFilterOption,
  Facet,
  FilterOption,
  SelectedFiltersType
} from "../types";

const WebFilteringSection = ({ listingPageData, listingFilters }) => {
  const history = useHistory();
  const location = useLocation();
  const routeSlug = useRouteSlug();
  const selectedByQuery = useSelectedByQuery();
  const assistiveFilter = useAssistiveFilters(listingPageData);
  const selectedFiltersData = useSelectedFilters(listingPageData);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    {}
  );

  useEffect(() => {
    if (listingPageData && isEmpty(selectedByQuery)) {
      setSelectedFilters({});
    }
  }, [listingPageData]);

  const updateSelectedFilters = useCallback(
    (selected, latestSelectedFilters = null) => {
      let updated: SelectedFiltersType = {
        ...(latestSelectedFilters || selectedFilters)
      };

      if (selected) {
        if (Array.isArray(selected)) {
          selected.forEach(item => {
            updated[item.queryParam] = item;
          });
        } else {
          updated[selected.queryParam] = selected;
        }
        setSelectedFilters(selected => ({ ...selected, ...updated }));
      } else {
        setSelectedFilters({});
      }

      return updated;
    },
    [selectedFilters]
  );

  const onAssistiveFilterClick = (filter: AssistiveFilterOption) => {
    updateSelectedFilters(null);
    history.push(`/${routeSlug}${filter.url}`);
  };

  const getUpdatedQuery = useCallback(
    (updatedSelection: SelectedFiltersType) => {
      const { q: searchQuery } = qs.parse(location.search);
      const queryObject = Object.values(updatedSelection).reduce(
        (acc, filter) => {
          acc[filter.queryParam] = filter.selectedOptions.map(
            option => option.key
          );
          return acc;
        },
        {}
      );
      const query = qs.stringifyUrl(
        {
          url: location.pathname,
          query: { ...queryObject, q: searchQuery }
        },
        { skipNull: true, skipEmptyString: true, encode: false }
      );

      return query;
    },
    [selectedFilters, location]
  );

  const onSelection = useCallback(
    (selected, latestSelectedFilters) => {
      const updatedSelection = updateSelectedFilters(
        selected,
        latestSelectedFilters
      );
      const query = getUpdatedQuery(updatedSelection);

      if (selected.queryParam === FILTER_QUERY_PARAMS.SORT) {
        PlpAnalytics.sort({
          sort_by: selected?.selectedOptions?.[0]?.defaultValue?.toLowerCase(),
          sort_location: SORT_LOCATION.PRODUCT_LIST
        });
      } else {
        handleFilterEvent(selected, updatedSelection);
      }

      history.push(query);
    },
    [updateSelectedFilters, getUpdatedQuery]
  );

  const tags = useMemo(
    () =>
      selectedFiltersData?.flatMap(filterData => {
        if (filterData.type === FILTER_TYPES.PRICE) return [];
        const { data: filterOptions, ...filterOnlyData } = filterData;
        return filterOptions.map(option => ({
          filter: { queryParam: option.queryParam, ...filterOnlyData },
          ...option
        }));
      }),
    [selectedFiltersData]
  );

  const onClearAll = useCallback(() => {
    updateSelectedFilters(null);
    const { q } = qs.parse(location.search);
    const url = qs.stringifyUrl(
      { url: location.pathname, query: { q } },
      { skipNull: true, skipEmptyString: true, encode: false }
    );
    history.push(url);
  }, [updateSelectedFilters]);

  const onTagRemove = useCallback(
    tag => {
      const newFilterSelection = {
        ...selectedFilters[tag.filter.queryParam],
        selectedOptions: selectedFilters[
          tag.filter.queryParam
        ].selectedOptions.filter(option => option.key !== tag.key)
      };
      const updatedSelection = updateSelectedFilters(newFilterSelection);
      const query = getUpdatedQuery(updatedSelection);
      history.push(query);
    },
    [updateSelectedFilters]
  );

  return (
    <View.desktop>
      {assistiveFilter && (
        <AssistiveFiltersSection
          staticFilter={assistiveFilter.prev_selection}
          filters={assistiveFilter.list}
          onFilterClick={onAssistiveFilterClick}
        />
      )}
      <SelectedFiltersContext.Provider
        value={{
          selectedFilters,
          setSelectedFilters,
          updateSelectedFilters,
          onSelection
        }}
      >
        <ProductListingFilters filters={listingFilters} />
        <FilteredBy
          tagList={tags}
          onTagRemove={onTagRemove}
          onClearAll={onClearAll}
        />
      </SelectedFiltersContext.Provider>
    </View.desktop>
  );
};

export const SelectedFiltersContext = createContext<{
  selectedFilters: SelectedFiltersType;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Facet & {
        selectedOptions: FilterOption[];
      };
    }>
  >;
  updateSelectedFilters: (selected?: any) => {
    [key: string]: Facet & {
      selectedOptions: FilterOption[];
    };
  };
  onSelection: (...args) => void;
}>(null);

export default WebFilteringSection;
