import qs from "query-string";

import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import {
  webapiPost,
  webapiGet,
  webapiPatch,
  webapiDelete,
  webapiPut
} from "../webapis/core";
import { OrderSummary, OrderSummaryRequestBody } from "./cartService";
import { DELIVERY_TYPES, TOAST_TYPES } from "@/constants";
import { setCheckoutGuestToken } from "@/util/browserStorage";
import { toastMsg } from "@/util/toast";

class CheckoutService extends ApiService {
  private fetchingPointObj = null;
  async getSavedAddresses(deliveryType) {
    const queryParams = qs.stringify({
      deliveryType
    });
    const endpoint = `${this.getAddressBaseUrl()}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async saveAddress(address, isGuest = false) {
    const endpoint = `${this.getAddressBaseUrl(isGuest)}`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      address,
      this.getVisitorId()
    ).request;

    if (response.data.guestAccessToken) {
      setCheckoutGuestToken(response.data.guestAccessToken);
    }

    return response.data;
  }

  async saveAddressWithOTP(verificationData: {
    otpToken: string;
    otpCode: string;
    isGuestUser?: boolean;
  }) {
    const endpoint = `${this.getAddressBaseUrl(
      verificationData.isGuestUser
    )}/complete`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { token: verificationData.otpToken, otp: verificationData.otpCode },
      this.getVisitorId()
    ).request;

    if (response.data.guestAccessToken) {
      setCheckoutGuestToken(response.data.guestAccessToken);
    }

    return response.data;
  }

  async updateAddressWithOTP(verificationData: {
    otpToken: string;
    otpCode: string;
    addressId: number;
  }) {
    const endpoint = `${this.getAddressBaseUrl()}/${
      verificationData.addressId
    }/complete`;

    const response = await webapiPut(
      this.getAccessToken(),
      endpoint,
      { token: verificationData.otpToken, otp: verificationData.otpCode },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async resendOtp(token) {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;
    const endpoint = `${protocol}${baseURL}${port}${versionInfo}${BEConfig.customerApi.userHandle}/resend-otp`;
    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { token },
      this.getVisitorId()
    ).request;
    return response;
  }

  async updateAddress(updatedAddress) {
    const endpoint = `${this.getAddressBaseUrl()}/${updatedAddress.addressId}`;

    const response = await webapiPut(
      this.getAccessToken(),
      endpoint,
      updatedAddress,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async removeAddress(addressId) {
    const endpoint = `${this.getAddressBaseUrl()}/${addressId}`;

    const response = await webapiDelete(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async fetchCollectionPointsData({ lat, lng }) {
    const {
      commonApi: {
        baseURL,
        protocol,
        port,
        versionInfo,
        collectionPointsEndpoint
      }
    } = BEConfig;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${collectionPointsEndpoint}?lat=${lat}&lng=${lng} `;
    this.abortFetchingPointsData(this.fetchCollectionPointsData);
    this.fetchingPointObj = webapiGet(
      this.getAccessToken(),
      apiURL,
      this.getVisitorId()
    );
    this.fetchingPointObj.creator = this.fetchCollectionPointsData;

    const response = await this.fetchingPointObj.request;

    this.fetchingPointObj = null;
    return response.data;
  }

  async fetchInStorePickupData() {
    const {
      commonApi: { baseURL, protocol, port, versionInfo, common, shops }
    } = BEConfig;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${common}/${shops}`;

    this.abortFetchingPointsData(this.fetchInStorePickupData);
    this.fetchingPointObj = webapiGet(
      this.getAccessToken(),
      apiURL,
      this.getVisitorId()
    );
    this.fetchingPointObj.creator = this.fetchInStorePickupData;

    const response = await this.fetchingPointObj.request;

    this.fetchingPointObj = null;
    return response.data;
  }

  private abortFetchingPointsData(caller: Function) {
    if (this.fetchingPointObj && this.fetchingPointObj.creator !== caller) {
      this.fetchingPointObj.cancel();
    }
  }

  public async payByCheckoutCom(paymentData) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.checkoutApi.payCheckoutCom
    }`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      paymentData,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async saveCreditCard(sessionId) {
    const endpoint = `${this.getBaseUrl()}/checkout-com/credit-card`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { "cko-session-id": sessionId },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async getCheckoutData() {
    const response = await webapiGet(
      this.getAccessToken(),
      this.getBaseUrl(),
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  public async getOrderSummary({
    deliveryType = DELIVERY_TYPES.DELIVERY_ADDRESS
  }: OrderSummaryRequestBody): Promise<OrderSummary> {
    const endpoint = `${this.getBaseUrl()}/summary`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { deliveryType },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async updateCheckoutData({ checkoutId, changes }) {
    const endpoint = `${this.getBaseUrl()}/${checkoutId}`;

    const response = await webapiPatch(
      this.getAccessToken(),
      endpoint,
      changes,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async setUseMyWallet(data) {
    const endpoint = `${this.getBaseUrl()}/set-wallet`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      data,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async placeOrder(additionalData?: { personalId?: string }) {
    const payload = Object.fromEntries(
      Object.entries(additionalData).filter(
        ([_, value]) =>
          value !== undefined &&
          value !== null &&
          value !== "" &&
          !Number.isNaN(value)
      )
    );

    const endpoint = `${this.getBaseUrl()}/order`;
    try {
      const response = await webapiPost(
        this.getAccessToken(),
        endpoint,
        payload,
        this.getVisitorId()
      ).request;

      return response;
    } catch (error) {
      const message = error.response?.data?.message;
      if (message) {
        toastMsg(TOAST_TYPES.ERROR, message, true);
      }
      console.error("Error while placing order", error);
      return error.response;
    }
  }

  public async getVoucherList(data) {
    const endpoint = `${this.getBaseUrl()}/getVoucherCodes`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      data,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  public async applyVoucher({
    voucherCode,
    binNo
  }: {
    voucherCode: string;
    binNo?: string;
  }) {
    const endpoint = `${this.getBaseUrl()}/apply-voucher/`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { voucherCode, binNo },
      this.getVisitorId()
    ).request;

    return response;
  }

  public async removeVoucher() {
    const endpoint = `${this.getBaseUrl()}/voucher/`;

    const response = await webapiDelete(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response;
  }

  private getAddressBaseUrl(isGuest = false) {
    const {
      baseURL,
      protocol,
      port,
      versionInfo,
      addressHandle,
      guestAddressHandle
    } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${
      isGuest ? guestAddressHandle : addressHandle
    }`;

    return url;
  }

  private getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, checkoutHandle } =
      BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;

    return url;
  }
}

const instance = new CheckoutService();

export default instance;
