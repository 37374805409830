export const SECTION_HEADER_TYPES = {
  HEADER_BOTTOM: "headerBottom",
  HEADER_SHOP_BY_STORE: "headerShopByStore"
};

export const SECTION_HEADER_TYPES_CMS = {
  HEADER_BOTTOM: "HEADER_BOTTOM",
  HEADER_SHOP_BY_STORE: "HEADER_SHOP_BY_STORE"
};

export const SECTION_SLUGS = {
  HEADER: "header",
  CHECKOUT: "checkout",
  PDP: "pdp",
  PLP: "plp",
  CART: "cart",
  ORDER_SUCCESS: "order-success"
};

export const SECTION_PDP_TYPES = {
  PDP_TOP: "pdpTop",
  PDP_SECTION_ONE: "pdpSectionOne",
  PDP_SECTION_TWO: "pdpSectionTwo"
};

export const SECTION_PLP_TYPES = {
  PLP_TOP: "PLP_TOP",
  EMPTY_RESULTS: "EMPTY_RESULTS"
};

export const SECTION_PDP_TYPES_CMS = {
  PDP_TOP: "PDP_TOP",
  PDP_SECTION_ONE: "PDP_SECTION_ONE",
  PDP_SECTION_TWO: "PDP_SECTION_TWO"
};

export const SECTION_CART_TYPES = {
  CART_TOP: "cartTop",
  CART_VAT: "cartVat"
};

export const SECTION_CART_TYPES_CMS = {
  CART_TOP: "CART_TOP",
  CART_VAT: "CART_VAT"
};

export const SECTION_CHECKOUT_TYPES = {
  CHECKOUT_TOP: "checkoutTop"
};

export const SECTION_CHECKOUT_TYPES_CMS = {
  CHECKOUT_TOP: "CHECKOUT_TOP"
};

export const SECTION_ORDER_SUCCESS_TYPES = {
  ORDER_SUCCESS_TOP: "orderSuccessTop",
  ORDER_SUCCESS_SECTION_ONE: "orderSuccessSectionOne"
};

export const SECTION_ORDER_SUCCESS_TYPES_CMS = {
  ORDER_SUCCESS_TOP: "ORDER_SUCCESS_TOP",
  ORDER_SUCCESS_SECTION_ONE: "ORDER_SUCCESS_SECTION_ONE"
};

export const SECTION_TABS_MODULE_TYPE = "tabs_inline_content";

export const SECTION_TABS_INLINE_CONTENT_SLUGS = {
  SHIPPING_AND_DELIVERY: "shipping_and_delivery",
  RETURNS_AND_CANCELLATION: "returns_and_cancellation"
};

export const SECTION_ACCOUNT_TYPES = {
  MY_ACCOUNT: "my-account"
};

export const SECTION_ACCOUNT_SLUGS = {
  CMS_WALLET_FAQ_ACCORDION: "cms-wallet-faq-accordion",
  MY_WALLET_FAQ: "my-wallet-faq"
};
