import { GA_EVENTS } from "./events";
import { pushGaProductClick } from "./utils";

class CustomerAnalyticsService {
  trackLoyaltyProgramView = ({
    customerId,
    smilePoints,
    loyaltyMonetaryValue
  }) => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.loyaltyProgramViewed,
      customerId,
      smilePoints,
      loyaltyMonetaryValue
    });
  };

  trackLoyaltyRedeemButtonClick = ({
    customerId,
    smilePoints,
    loyaltyMonetaryValue
  }) => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.loyaltyProgramRedeemButtonClick,
      customerId,
      smilePoints,
      loyaltyMonetaryValue
    });
  };

  trackLoyaltyRedeemConfirmClick = ({
    customerId,
    smilePoints,
    selectedRedemptionPoints,
    selectedRedemptionTransactionAmount,
    loyaltyMonetaryValue
  }) => {
    pushGaProductClick.pushGa({
      event: GA_EVENTS.loyaltyProgramRedeemConfirmClick,
      customerId,
      smilePoints,
      selectedRedemptionPoints,
      selectedRedemptionTransactionAmount,
      loyaltyMonetaryValue
    });
  };

  trackMyWalletView = ({ customerId, countryId, language, myWalletAmount }) => {
    pushGaProductClick.pushGa({
      event: "myWalletViewed",
      customerId,
      countryId,
      language,
      myWalletAmount
    });
  };
}

export default CustomerAnalyticsService;
