import Loadable from "@loadable/component";

const AppOnlyItemInfoModal = Loadable(
  () =>
    import(
      /* webpackChunkName: "app_only_item_info_modal" */ "./appOnlyItemInfoModal"
    )
);

export default AppOnlyItemInfoModal;
